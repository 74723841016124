import { render, staticRenderFns } from "./storeInfo.vue?vue&type=template&id=387713ee&"
import script from "./storeInfo.vue?vue&type=script&lang=js&"
export * from "./storeInfo.vue?vue&type=script&lang=js&"
import style0 from "./storeInfo.vue?vue&type=style&index=0&id=387713ee&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QDialog,QCard,QForm,QCardSection,QItemLabel,QInput,QIcon,QTooltip,QSelect,QPopupProxy,QDate,QBtn,QCardActions,QField,QItem,ClosePopup} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QDialog,QCard,QForm,QCardSection,QItemLabel,QInput,QIcon,QTooltip,QSelect,QPopupProxy,QDate,QBtn,QCardActions,QField,QItem})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {ClosePopup})
