
import stores from '@/services/stores';
import moment from 'moment';
export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['visible', 'selectedStore'],
  data() {
    return {
      internalVisible: this.visible,
      selectedDate: '',
      shortDescription: '',
      openTime: '10:00',
      closeTime: '20:00',
      isOpen: 'open',
      isEdit: false,
      isEditIndex: null
    };
  },
  computed: {
    timeOptions() {
      return [
        '00:00',
        '01:00',
        '02:00',
        '03:00',
        '04:00',
        '05:00',
        '06:00',
        '07:00',
        '08:00',
        '09:00',
        '10:00',
        '11:00',
        '12:00',
        '13:00',
        '14:00',
        '15:00',
        '16:00',
        '17:00',
        '18:00',
        '19:00',
        '20:00',
        '21:00',
        '22:00',
        '23:00'
      ];
    }
  },
  watch: {
    visible(newValue) {
      this.internalVisible = newValue;
    },
    internalVisible(newValue) {
      if (!newValue) {
        this.$emit('close');
      }
    }
  },
  methods: {
    optionsH(date) {
      if (this.selectedStore?.openDate && this.selectedStore?.closeDate) {
        return (
          date >= this.selectedStore?.openDate &&
          date <= this.selectedStore?.closeDate
        );
      } else {
        return date >= this.selectedStore?.openDate;
      }
    },
    submit(op) {
      if (!this.shortDescription) {
        this.$q.notify({
          message: 'Please Add Short Description',
          color: 'red'
        });
        return;
      }
      if (!this.selectedDate) {
        this.$q.notify({
          message: 'Please Add Date',
          color: 'red'
        });
        return;
      }
      //
      let special = this.selectedStore.storeTimings?.filter(item =>
        item.hasOwnProperty('dates')
      );
      const regular = this.selectedStore.storeTimings?.filter(
        item => !item.hasOwnProperty('dates')
      );
      console.log("regular:", regular)
      //
      const check = this.isOpen === 'close';
      const time = {
        dates: [moment(this.selectedDate).format('YYYY-MM-DD')],
        day: this.shortDescription,
        ...(!check && {
          openingTime: this.openTime,
          closingTime: this.closeTime
        })
      };
      // index edit
      special.splice(
        this.isEdit ? this.isEditIndex : special.length,
        this.isEdit ? 1 : 0,
        time
      );
      //
      let dataT = [...regular, ...special]?.map(item => {
        return {
          ...item,
          openingTime: item.openingTime?.slice(0, 5),
          closingTime: item.closingTime?.slice(0, 5)
        };
      });

      const payload = {
        storeId: this.selectedStore && this.selectedStore._id,
        storeTimings: [...dataT]
      };
      stores
        .updateStore(payload, true)
        .then(() => {
          this.$emit('refetchData');
          if (op) {
            this.closeModal();
          } else {
            this.shortDescription = '';
            this.selectedDate = '';
            this.openTime = '10:00';
            this.closeTime = '20:00';
            this.isOpen = 'open';
          }
          this.$q.notify({
            message: this.$store.state.user.translate.updated_successfully,
            color: 'green'
          });
        })
        .catch(error => {
          console.log('error:', error);
        });
    },
    modelHandler(v) {
      this.isOpen = v;
    },
    closeModal() {
      this.internalVisible = false;
    },
    saveAndClose() {
      // Handle save logic here
      this.submit(true);
      // this.closeModal();
    },
    saveAndContinue() {
      // Handle save logic here
      this.submit(false);
      // this.shortDescription = '';
      // this.selectedDate = '';
    }
  }
};
