
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import {
  format,
  startOfWeek,
  endOfWeek,
  startOfYesterday,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
  addYears,
  addDays,
  addWeeks,
  addMonths,
  differenceInDays,
  getDay
} from 'date-fns';
import FilterStoreLocation from './FilterStoreLocation';
import moment from 'moment';
import CalenderDays from '@/components/Filter/FilterComponents/CalenderDays.vue';
import {getDayDiffFromMonday, getStartDayOfWeek} from '@/util/calendar.js'

export default {
  components: { FilterStoreLocation },
  props: {
    setdatefilter: {
      required: true,
      type: Function,
      default: () => {}
    },
    dateParentType: {
      type: String,
      required: true,
      default: ''
    }
  },
  data() {
    return {
      attrs: [
        {
          key: 'Holidays',
          highlight: true,
          popover: {
            label: ''
          },
          dates: []
        }
      ],
      retailCalendarMode: false,
      retailMonth: null,
      retailMonths: [],
      retailWeek: '',
      filterRetailWeeks: [],
      retailYear: null,
      monthList: [
        { label: this.$t('FilterBar.January'), value: 'Jan' },
        { label: this.$t('FilterBar.February'), value: 'Feb' },
        { label: this.$t('FilterBar.March'), value: 'Mar' },
        { label: this.$t('FilterBar.April'), value: 'Apr' },
        { label: this.$t('FilterBar.May'), value: 'May' },
        { label: this.$t('FilterBar.June'), value: 'Jun' },
        { label: this.$t('FilterBar.July'), value: 'Jul' },
        { label: this.$t('FilterBar.August'), value: 'Aug' },
        { label: this.$t('FilterBar.September'), value: 'Sep' },
        { label: this.$t('FilterBar.October'), value: 'Oct' },
        { label: this.$t('FilterBar.November'), value: 'Nov' },
        { label: this.$t('FilterBar.December'), value: 'Dec' }
      ],
      retailMonthsOptions: [
        { label: this.$t('FilterBar.January'), value: 'Jan' },
        { label: this.$t('FilterBar.February'), value: 'Feb' },
        { label: this.$t('FilterBar.March'), value: 'Mar' },
        { label: this.$t('FilterBar.April'), value: 'Apr' },
        { label: this.$t('FilterBar.May'), value: 'May' },
        { label: this.$t('FilterBar.June'), value: 'Jun' },
        { label: this.$t('FilterBar.July'), value: 'Jul' },
        { label: this.$t('FilterBar.August'), value: 'Aug' },
        { label: this.$t('FilterBar.September'), value: 'Sep' },
        { label: this.$t('FilterBar.October'), value: 'Oct' },
        { label: this.$t('FilterBar.November'), value: 'Nov' },
        { label: this.$t('FilterBar.December'), value: 'Dec' }
      ],
      retailMonthSelection: '',
      retailWeeks: [],
      retailYears: [],
      calenderMode: 'range',
      dragValue: null,
      selectedPeriodState: false,
      selectedDateRange: [],
      dateOptionsSelectedModel: {
        label: this.$t('DateRangeSelector.custom_range'),
        value: 'CUSTOM_RANGE'
      },
      dateOptionsComparedModel: {
        label: this.$t('DateRangeSelector.previous_period'),
        value: 'PREVIOUS_PERIOD'
      },
      marketingCampaignMode: false,
      marketingCampaignOption: null,
      marketingCampaignOptionList: [],
      dateOptionsSelected: [
        {
          label: this.$t('DateRangeSelector.today'),
          value: 'TODAY'
        },
        {
          label: this.$t('DateRangeSelector.yesterday'),
          value: 'YESTERDAY'
        },
        {
          label: this.$t('DateRangeSelector.this_week'),
          value: 'THIS_WEEK'
        },
        {
          label: this.$t('DateRangeSelector.last_week'),
          value: 'LAST_WEEK'
        },
        {
          label: this.$t('DateRangeSelector.this_month'),
          value: 'THIS_MONTH'
        },
        {
          label: this.$t('DateRangeSelector.last_month'),
          value: 'LAST_MONTH'
        },
        {
          label: this.$t('DateRangeSelector.this_year'),
          value: 'THIS_YEAR'
        },
        {
          label: this.$t('DateRangeSelector.last_year'),
          value: 'LAST_YEAR'
        },
        {
          label: this.$t('DateRangeSelector.custom_range'),
          value: 'CUSTOM_RANGE'
        } // {
        //   label: this.$t('DateRangeSelector.marketing_campaigns'),
        //   value: 'marketing_campaigns'
        // }
      ],
      dateOptionsCompared: [
        {
          label: this.$t('DateRangeSelector.previous_period'),
          value: 'PREVIOUS_PERIOD'
        },
        {
          label: this.$t('DateRangeSelector.previous_year_same_period'),
          value: 'PREVIOUS_YEAR_SAME_PERIOD'
        },
        {
          label: this.$t('FilterBar.previous_year_same_day_of_week'),
          value: 'PREVIOUS_YEAR_SAME_DAY_OF_WEEK'
        }
        // {
        //   label: this.$t('DateRangeSelector.special_calendar'),
        //   value: 'tutuanna_calender'
        // }
      ],
      selectedPeriod: {
        start: new Date(), // Jan 16th, 2018
        end: new Date() // Jan 19th, 2018
      },
      comparedPeriod: {
        start: addDays(new Date(), -1), // Jan 16th, 2018
        end: addDays(new Date(), -1) // Jan 19th, 2018
      },
      lastTimeStamp: Date.now(),
      startDayFromMonday: 0,
      startDayOfWeek: 1,
    };
  }, // data
  computed: {
    ...mapState('user', ['profile', 'startOfWeek', "defaultSettings"]),
    ...mapGetters('user', ['getLocale']),
    ...mapState('filter', ['filter', 'retailCalendar', 'holidays']),
    ...mapState('store', ['stores']),
    ...mapState('comparison', ['marketingCampaign', 'compareDates']),
    // ...mapState('comparison', []),
    tutuannaMode() {
      return this.dateOptionsComparedModel.value === 'tutuanna_calender';
    },
    selectDragAttribute() {
      return {
        popover: {
          visibility: this.tutuannaMode ? 'hover' : 'none',
          isInteractive: false // Defaults to true when using slot
        }
      };
    },

    dragComparedValue: function() {
      let value = '';
      if (this.dragValue && this.compareDates) {
        value = this.getComparedDate(this.formatDate(this.dragValue));
        return this.formatDate(value);
      } else {
        return value;
      }
    },
    numberfRetailWeeks() {
      return this.retailCalendar.find(
        v => v.label.toString() === this.retailYear.value.toString()
      )?.numberOfWeeks;
    },
    startDateOfRetailYear() {
      return this.retailCalendar.find(
        v => v.label.toString() === this.retailYear?.value.toString()
      )?.startDate;
    }
  }, // computed
  watch: {
    stores(newData){
      let sStores = localStorage.getItem("storeCodes");
      this.updateCloseCalender(newData, sStores?.split(','))
    },
   
    retailYear() {
      let num = this.retailCalendar.find(
        v => v.label.toString() === this.retailYear.value.toString()
      )?.numberOfWeeks;
      let startDateRetail = this.retailCalendar.find(
        v => v.label.toString() === this.retailYear.value.toString()
      )?.startDate;
      this.retailMonthSelection = {
        label: this.$t(`FilterBar.${moment(startDateRetail).format('MMMM')}`),
        value: moment(startDateRetail).format('MMM')
      };

      let monthIndex = this.monthList.findIndex(
        v => v.value === this.retailMonthSelection.value
      );
      this.retailMonthsOptions = [
        ...this.monthList.slice(monthIndex, 12),
        ...this.monthList.slice(0, monthIndex)
      ];
      // this.retailMonthSelection = { label: this.$t('January'), value: 'Jan' };
      this.getRetailWeeks(startDateRetail, 12, num);
    },
    retailWeek() {
      if (
        new Date(this.retailWeek?.value?.startingPeriod) < new Date() &&
        new Date(this.retailWeek?.value?.endingPeriod) < new Date()
      ) {
        this.selectedPeriod = {}
        this.$set(this.selectedPeriod, "start", new Date(this.retailWeek?.value?.startingPeriod))
        this.$set(this.selectedPeriod, "end", new Date(this.retailWeek?.value?.endingPeriod))
      } else {
        this.$q.notify({
          type: 'negative',
          position: 'top',
          message: `Please select range lower than present day`
        });
      }
    },
    retailMonth() {
      if (
        new Date(this.retailMonth?.value?.startingPeriod) < new Date() &&
        new Date(this.retailMonth?.value?.endingPeriod) < new Date()
      ) {
        this.selectedPeriod = {}
        this.$set(this.selectedPeriod, "start", new Date(this.retailMonth?.value?.startingPeriod))
        this.$set(this.selectedPeriod, "end", new Date(this.retailMonth?.value?.endingPeriod))
      } else {
        this.$q.notify({
          type: 'negative',
          position: 'top',
          message: `Please select range lower than present day`
        });
      }
    },
    retailMonthSelection() {
      this.filterRetailWeeks = this.retailWeeks.filter(v =>
        v.label.includes(this.getSelectedMonth(this.retailMonthSelection.value))
      );
      this.retailWeek = this.filterRetailWeeks[0];
    },
    marketingCampaign() {
      if (this.marketingCampaign && this.marketingCampaign.length > 0) {
        this.dateOptionsSelected = [
          ...this.dateOptionsSelected,
          {
            label: this.$t('DateRangeSelector.marketing_campaigns'),
            value: 'marketing_campaigns'
          }
        ];
      }
    },
    // compareDates() {
    //   if (this.compareDates && this.compareDates.length > 0) {
    //     this.dateOptionsCompared = [
    //       ...this.dateOptionsCompared,
    //       {
    //         label: this.$t('DateRangeSelector.special_calendar'),
    //         value: 'tutuanna_calender'
    //       }
    //     ];
    //   }
    // },
    holidays() {
      this.attrs = [
        ...this.holidays.map(v => ({
          key: 'Holidays',
          // highlight: true,
          popover: {
            label: v.value,
            labelClass: 'white-text',
            labelStyle: { color: 'white' },
            class: 'white-text'
          },
          dates: v.date,
          bar: {
            color: 'red',
            class: 'my-dot-class'
          }
        }))
      ];
    },
    'filter.daysOfWeek': {
      handler: 'refreshPage'
    },
  
    // 'filter.storeCodes': {
    //   handler: 'refreshPage'
    // },
    'filter.storeCodes':function (newData) {
      // handler: 'refreshPage'
      this.refreshPage()
      this.updateCloseCalender(this.stores, newData)
    },
    
    selectedPeriod: function() {
      let sStores = localStorage.getItem("storeCodes");
    this.updateCloseCalender(this.stores, sStores?.split(','))
      this.getSelectedDateRange();
      if (this.tutuannaMode) {
        this.comparedPeriod = this.selectedDateRange.map(
          dat => new Date(this.getComparedDate(dat))
        );
      } else {
        if (this.dateOptionsComparedModel.value === 'PREVIOUS_PERIOD') {
          const diffDays = differenceInDays(
            this.selectedPeriod.start,
            this.selectedPeriod.end
          );
          const diffDaysPositive =
            diffDays < 0 ? (diffDays - 1) * -1 : diffDays + 1;
          const weeks = Math.ceil((diffDaysPositive / 7).toFixed(2));
          this.comparedPeriod.start = addWeeks(
            this.selectedPeriod.start,
            -weeks
          );
          this.comparedPeriod.end = addWeeks(this.selectedPeriod.end, -weeks);
        } else if (
          this.dateOptionsComparedModel.value === 'previous_year_same_period'
        ) {
          this.comparedPeriod.start = addYears(
            new Date(this.selectedPeriod.start),
            -1
          );
          this.comparedPeriod.end = addYears(
            new Date(this.selectedPeriod.end),
            -1
          );
        } else if (
          this.dateOptionsComparedModel.value ===
          'previous_year_same_day_of_week'
        ) {
          this.comparedPeriod.start = addWeeks(
            new Date(this.selectedPeriod.start),
            -52
          );
          this.comparedPeriod.end = addWeeks(
            new Date(this.selectedPeriod.end),
            -52
          );
        } else if (
          this.dateOptionsComparedModel.value === 'PREVIOUS_YEAR_SAME_WEEK'
        ) {
          // this.comparedPeriod= {}
          const prevYear =
            this.retailCalendar.find(
              v =>
                v.label.toString() ===
                (
                  this.retailYear?.value ??
                  new Date(this.selectedPeriod.start).getFullYear() - 1
                ).toString()
            ) || null;
          const weeksToAdd = this.retailWeeks.findIndex(
            v => v === this.retailWeek
          );
          if (prevYear) {
            const startDateRetail = prevYear.startDate;
            const endOfStartDateRetail = moment(
              addDays(new Date(startDateRetail), 6)
            ).format('YYYY-MM-DD');
            // let startDateRetail = prevYear.startDate;

            this.comparedPeriod.start = addWeeks(
              new Date(startDateRetail),
              weeksToAdd
            );
            this.comparedPeriod.end = addWeeks(
              new Date(endOfStartDateRetail),
              weeksToAdd
            );
          } else {
            const currYear = this.retailCalendar.find(
              v => v.label.toString() === this.retailYear.value.toString()
            );
            const startDateRetail = currYear.startDate;
            const endOfStartDateRetail = moment(
              addDays(new Date(startDateRetail), 6)
            ).format('YYYY-MM-DD');
            this.comparedPeriod.start = addWeeks(
              new Date(startDateRetail),
              weeksToAdd - 52
            );
            this.comparedPeriod.end = addWeeks(
              new Date(endOfStartDateRetail),
              weeksToAdd - 52
            );
          }
        } else if (
          this.dateOptionsComparedModel.value === 'previous_year_same_month'
        ) {
          let retailDetails =
            this.retailCalendar.find(
              v => v.label.toString() === (this.retailYear.value - 1).toString()
            ) || null;
          if (retailDetails) {
            const prevYearMonths = this.getRetailMonths(
              retailDetails.startDate,
              12,
              retailDetails.numberOfWeeks
            );

            const currMonthIndex = this.retailMonths.findIndex(
              v => v === this.retailMonth
            );

            this.comparedPeriod.start = new Date(
              prevYearMonths[currMonthIndex]?.value?.startingPeriod
            );
            this.comparedPeriod.end = new Date(
              prevYearMonths[currMonthIndex]?.value?.endingPeriod
            );
          } else {
            this.comparedPeriod.start = addYears(this.selectedPeriod.start, -1);
            this.comparedPeriod.end = addYears(this.selectedPeriod.end, -1);
          }
        }
      }
    },
    dateOptionsSelectedModel(val) {
      // if (val.value === 'retail_calendar') {
      //   this.dateOptionsCompared = [
      //     ...this.dateOptionsCompared,
      //     {
      //       label: this.$t('FilterBar.previous_year_same_week'),
      //       value: 'PREVIOUS_YEAR_SAME_WEEK'
      //     }
      //   ];
      // } else {
      //   this.dateOptionsCompared = this.dateOptionsCompared.filter(
      //     v => v.value !== 'PREVIOUS_YEAR_SAME_WEEK'
      //   );
      //   this.dateOptionsComparedModel = {
      //     label: this.$t('DateRangeSelector.previous_period'),
      //     value: 'PREVIOUS_PERIOD'
      //   };
      // }
      // if (val.value === 'retail_calendar_monthly') {
      //   this.dateOptionsCompared = [
      //     ...this.dateOptionsCompared,
      //     {
      //       label: this.$t('FilterBar.previous_year_same_month'),
      //       value: 'previous_year_same_month'
      //     }
      //   ];
      // } else {
      //   this.dateOptionsCompared = this.dateOptionsCompared.filter(
      //     v => v.value !== 'previous_year_same_month'
      //   );
      //   this.dateOptionsComparedModel = {
      //     label: this.$t('DateRangeSelector.previous_period'),
      //     value: 'PREVIOUS_PERIOD'
      //   };
      // }
      this.getSelectedDateRange();
      if (this.tutuannaMode) {
        this.comparedPeriod = this.selectedDateRange.map(
          dat => new Date(this.getComparedDate(dat))
        );
      } else {
        if (this.dateOptionsComparedModel.value === 'PREVIOUS_PERIOD') {
          const diffDays = differenceInDays(
            this.selectedPeriod.start,
            this.selectedPeriod.end
          );
          const diffDaysPositive =
            diffDays < 0 ? (diffDays - 1) * -1 : diffDays + 1;
          const weeks = Math.ceil((diffDaysPositive / 7).toFixed(2));
          this.comparedPeriod.start = addWeeks(
            this.selectedPeriod.start,
            -weeks
          );
          this.comparedPeriod.end = addWeeks(this.selectedPeriod.end, -weeks);
        } else if (
          this.dateOptionsComparedModel.value === 'previous_year_same_period'
        ) {
          this.comparedPeriod.start = addYears(
            new Date(this.selectedPeriod.start),
            -1
          );
          this.comparedPeriod.end = addYears(
            new Date(this.selectedPeriod.end),
            -1
          );
        } else if (
          this.dateOptionsComparedModel.value ===
          'previous_year_same_day_of_week'
        ) {
          this.comparedPeriod.start = addWeeks(
            new Date(this.selectedPeriod.start),
            -52
          );
          this.comparedPeriod.end = addWeeks(
            new Date(this.selectedPeriod.end),
            -52
          );
        }
      }
    },
    marketingCampaignOption: function() {
      if (!this.tutuannaMode && this.marketingCampaignMode) {
        if (this.dateOptionsComparedModel.value === 'PREVIOUS_PERIOD') {
          const diffDays = differenceInDays(
            this.selectedPeriod.start,
            this.selectedPeriod.end
          );
          const diffDaysPositive =
            diffDays < 0 ? (diffDays - 1) * -1 : diffDays + 1;
          const weeks = Math.ceil((diffDaysPositive / 7).toFixed(2));
          this.comparedPeriod.start = addWeeks(
            this.selectedPeriod.start,
            -weeks
          );
          this.comparedPeriod.end = addWeeks(this.selectedPeriod.end, -weeks);
        } else if (
          this.dateOptionsComparedModel.value === 'previous_year_same_period'
        ) {
          this.comparedPeriod.start = addYears(
            new Date(this.selectedPeriod.start),
            -1
          );
          this.comparedPeriod.end = addYears(
            new Date(this.selectedPeriod.end),
            -1
          );
        } else if (
          this.dateOptionsComparedModel.value ===
          'previous_year_same_day_of_week'
        ) {
          this.comparedPeriod.start = addWeeks(
            new Date(this.selectedPeriod.start),
            -52
          );
          this.comparedPeriod.end = addWeeks(
            new Date(this.selectedPeriod.end),
            -52
          );
        }
      }
    }
  },

  async created() {
    let sStores = localStorage.getItem("storeCodes");
    this.updateCloseCalender(this.stores, sStores?.split(','))
    this.firstTimeCheck()
    this.startDayFromMonday = getDayDiffFromMonday();
    this.startDayOfWeek = getStartDayOfWeek();
    if (this.retailCalendar.length > 0) {
      this.dateOptionsSelected = [
        ...this.dateOptionsSelected,
        {
          label: this.$t('FilterBar.retail_calendar_monthly'),
          value: 'retail_calendar_monthly'
        },
        {
          label: this.$t('FilterBar.retail_calendar'),
          value: 'retail_calendar'
        }
      ];

      this.retailYears = await this.retailCalendar.map(v => ({
        label: v.label,
        value: v.label
      }));
      
      // this.getRetailWeeks(
      //   this.startDateOfRetailYear,
      //   12,
      //   this.numberfRetailWeeks
      // );

      // this.retailYear = {
      //   label: new Date().getFullYear(),
      //   value: '2020'
      // };
    }
    
    
    this.$set(this.selectedPeriod, "start", new Date(this.filter.startingPeriod))
    this.$set(this.selectedPeriod, "end", new Date(this.filter.endingPeriod))
    this.$set(this.comparedPeriod, "start", new Date(this.filter.startingCompared))
    this.$set(this.comparedPeriod, "end", new Date(this.filter.endingCompared))
    
    this.dateOptionsSelected?.forEach((data) => {
      if (data.value == this.filter.selectedOption) {
        this.$set(this.dateOptionsSelectedModel, "value", data.value)
        this.$set(this.dateOptionsSelectedModel, "label", data.label)
        this.$set(this.dateOptionsSelectedModel, "disable", data.disable || false)
        this.dateOptionsSelectedModelChanged(data.value);
      }
    })
    this.dateOptionsCompared?.forEach((data) => {
      if (data.value == this.filter.comparedOption) {
        this.$set(this.dateOptionsComparedModel, "value", data.value)
        this.$set(this.dateOptionsComparedModel, "label", data.label)
        this.$set(this.dateOptionsComparedModel, "disable", data.disable || false)
        this.dateOptionsComparedModelChanged(data.value);
      }
    })
    if(this.filter.selectedOption == "CUSTOM_RANGE" || this.filter.selectedOption == "CUSTOM") {
      this.onDayClick()
    }
    if(this.filter.comparedOption == "CUSTOM") {
      this.onDayClickCompare()
    }
    await this.fetchHolidays();
    await this.fetchCompareDates();
    await this.fetchMarketingCampaign();
    this.getSelectedDateRange();
    this.marketingCampaignOptionList = this.marketingCampaign
      ? this.marketingCampaign.map(mc => {
          return {
            label: mc.campaignName,
            value: {
              startDate: mc.startDate,
              endDate: mc.endDate
            }
          };
        })
      : []; 
  this.setCalendarDatesToStore({selectedOption: this.dateOptionsSelectedModel.value, comparedOption: this.dateOptionsComparedModel.value, selectedPeriod: this.selectedPeriod, comparedPeriod: this.comparedPeriod})

  }, // created

  methods: {
    ...mapMutations('filter', [
      "setCalendarDatesToStore",
      'setStartingPeriod',
      'setEndingPeriod',
      'setStartingCompared',
      'setEndingCompared',
      'setCustomCalenderFlag',
      "firstTimeCheck"
    ]), // mapMutations
    ...mapActions('comparison', [
      'fetchCompareDates',
      'fetchMarketingCampaign'
    ]),
    ...mapActions('filter', ['fetchHolidays']),
    updateCloseCalender(data, selectedStores){
      const dataAccessStores = data?.dataAccessStores;
      if(dataAccessStores){
        const filteredStores = dataAccessStores.filter(store => selectedStores?.includes(store.storeNumber));
        const filteredTimings = filteredStores.flatMap(store => store.storeTimings.filter(timing => timing.dates));
        const formattedData = filteredTimings.flatMap(timing => timing.dates.map(date => (
          {
            "key": "Closed",
            "popover": {
                "label": timing.day,
                "labelClass": "white-text",
                "labelStyle": {
                    "color": "white"
                },
                "class": "white-text"
            },
            "dates": date,
            "bar": {
              style: {
                  backgroundColor: 'blue',
                },
                // "color": "blue",
                "class": "blue-bar"
            }
        })));
        const filter = this.attrs.filter(item => item.key != "Closed");
        this.attrs = [...filter, ...formattedData];
      }
    },
    onDayClick() {
      this.dateOptionsSelectedModel = {
        label: this.$t("custom_range"),
        value: "CUSTOM",
        disable: true,
      };
    },
    onDayClickCompare() {
      this.dateOptionsComparedModel = {
        label: this.$t('custom_range'),
        value: 'CUSTOM',
        disable: true
      };
    },
    getFormattedDate(date) {
      let retVal = date ? moment(new Date(date))?.format('YYYY/MM/DD') : "Invalid date";
      if(retVal.trim() == "Invalid date") {
        retVal = ""
      }
      return retVal;
    },
    getSelectedMonth(val) {
      if (val.includes('Jan')) return '/01/';
      else if (val.includes('Feb')) return '/02/';
      else if (val.includes('Mar')) return '/03/';
      else if (val.includes('Apr')) return '/04/';
      else if (val.includes('May')) return '/05/';
      else if (val.includes('Jun')) return '/06/';
      else if (val.includes('Jul')) return '/07/';
      else if (val.includes('Aug')) return '/08/';
      else if (val.includes('Sep')) return '/09/';
      else if (val.includes('Oct')) return '/10/';
      else if (val.includes('Nov')) return '/11/';
      else if (val.includes('Dec')) return '/12/';
    },
    getRetailMonths(startingDateVal, noOfMonths, noOfWeeks = 52) {
      let startingMonth = new Date(startingDateVal);
      let monthsVal = [];
      for (let i = 0; i < noOfMonths; i++) {
        let monthStart = moment(startingMonth).format('YYYY-MM-DD');
        let monthEnd = moment(
          addDays(addWeeks(startingMonth, (i + 1) % 3 === 0 ? 5 : 4), -1)
        ).format('YYYY-MM-DD');
        startingMonth = addWeeks(startingMonth, (i + 1) % 3 === 0 ? 5 : 4);
        monthsVal.push({
          label:
            moment(monthStart).format('YYYY/MM/DD') +
            ' - ' +
            moment(monthEnd).format('YYYY/MM/DD'),
          value: {
            startingPeriod: monthStart,
            endingPeriod: monthEnd
          }
        });
      }
      if (noOfWeeks === '53') {
        monthsVal[10].value.endingPeriod = moment(
          addWeeks(new Date(monthsVal[10].value.endingPeriod), 1)
        ).format('YYYY-MM-DD');
        monthsVal[10].label =
          monthsVal[10]?.value?.startingPeriod.replace('-', '/') +
          ' - ' +
          monthsVal[10]?.value?.endingPeriod.replace('-', '/');
        monthsVal[11].value.startingPeriod = moment(
          addWeeks(new Date(monthsVal[11].value.startingPeriod), 1)
        ).format('YYYY-MM-DD');
        monthsVal[11].value.endingPeriod = moment(
          addWeeks(new Date(monthsVal[11].value.endingPeriod), 1)
        ).format('YYYY-MM-DD');
        monthsVal[11].label =
          monthsVal[11].value?.startingPeriod.replace('-', '/') +
          ' - ' +
          monthsVal[11].value?.endingPeriod.replace('-', '/');
      }
      return monthsVal;
    },
    getRetailWeeks(startingDateVal, noOfMonths, noOfWeeks) {
      let startingDate = startingDateVal ? new Date(startingDateVal) : null;

      this.retailWeeks = [];
      this.retailMonths = this.getRetailMonths(
        startingDateVal,
        noOfMonths,
        noOfWeeks
      );
      this.retailMonth = startingDate ? this.retailMonths[0] : null;
      for (let i = 0; i < noOfWeeks; i++) {
        let weekStart = moment(startingDate).format('YYYY-MM-DD');
        let weekEnd = moment(addDays(addWeeks(startingDate, 1), -1)).format(
          'YYYY-MM-DD'
        );
        const weekVal = {
          label:
            moment(weekStart).format('YYYY/MM/DD') +
            ' - ' +
            moment(weekEnd).format('YYYY/MM/DD'),
          value: {
            startingPeriod: weekStart,
            endingPeriod: weekEnd
          }
        };
        startingDate = addWeeks(startingDate, 1);
        this.retailWeeks.push(weekVal);
      }
    },
    getSelectedDateRange() {
      let dates = [];
      let startDate = this.selectedPeriod.start;
      while (startDate <= this.selectedPeriod.end) {
        dates.push(startDate);
        startDate = addDays(startDate, 1);
      }
      this.selectedDateRange = dates.map(dat => this.formatDate(dat));
    },
    dateOptionsSelectedModelChanged() {
      const selectedOption = this.dateOptionsSelectedModel.value;
      this.marketingCampaignMode = false;
      this.retailCalendarMode = false;
      if (selectedOption === 'TODAY') {
        this.selectedPeriod = {};
        this.selectedPeriod.start = new Date();
        this.selectedPeriod.end = new Date();
      } else if (selectedOption === 'YESTERDAY') {
        this.selectedPeriod = {};
        const yesterday = startOfYesterday();
        this.selectedPeriod.start = yesterday;
        this.selectedPeriod.end = yesterday;
      } else if (selectedOption === 'THIS_WEEK') {
        this.selectedPeriod = {};
        this.selectedPeriod.start = startOfWeek(new Date(), { weekStartsOn: this.startDayOfWeek });
        this.selectedPeriod.end = new Date();
        // this.selectedPeriod.end = endOfWeek(new Date());
      } else if (selectedOption === "LAST_WEEK") {
        this.selectedPeriod = {};
        const d = addWeeks(new Date(), -1);
        this.selectedPeriod.start = startOfWeek(d, { weekStartsOn: this.startDayOfWeek });
        this.selectedPeriod.end = addDays(this.selectedPeriod.start, 6)
      } else if (selectedOption === 'THIS_MONTH') {
        this.selectedPeriod = {};
        this.selectedPeriod.start = startOfMonth(new Date());
        this.selectedPeriod.end = new Date();
        // this.selectedPeriod.end = endOfMonth(new Date());
      } else if (selectedOption === 'LAST_MONTH') {
        this.selectedPeriod = {};
        this.selectedPeriod.start = startOfMonth(addMonths(new Date(), -1));
        this.selectedPeriod.end = endOfMonth(addMonths(new Date(), -1));
      } else if (selectedOption === 'THIS_YEAR') {
        this.selectedPeriod = {};
        this.selectedPeriod.start = startOfYear(new Date());
        this.selectedPeriod.end = new Date();
        // this.selectedPeriod.end = endOfYear(new Date());
      } else if (selectedOption === 'LAST_YEAR') {
        this.selectedPeriod = {};
        this.selectedPeriod.start = addYears(startOfYear(new Date()), -1);
        this.selectedPeriod.end = addYears(endOfYear(new Date()), -1);
      } else if (selectedOption === 'marketing_campaigns') {
        this.marketingCampaignMode = true;
      } else if (selectedOption === 'retail_calendar') {
        this.retailCalendarMode = true;
      } else if (selectedOption === 'retail_calendar_monthly') {
        this.retailCalendarMode = true;
      }
      this.dateOptionsComparedModelChanged();
    },
    dateOptionsComparedModelChanged() {
      this.specialCalenderMode = false;
      // const selectedOption = this.dateOptionsSelectedModel.value;
      const comparedOption = this.dateOptionsComparedModel.value;
      this.calenderMode = 'range';
      if (comparedOption === 'PREVIOUS_PERIOD') {
        this.comparedPeriod = {};

        const diffDays = differenceInDays(
          this.selectedPeriod.start,
          this.selectedPeriod.end
        );
        const diffDaysPositive =
          diffDays < 0 ? (diffDays - 1) * -1 : diffDays + 1;
        const weeks = Math.ceil((diffDaysPositive / 7).toFixed(2));
        this.comparedPeriod.start = addWeeks(this.selectedPeriod.start, -weeks);
        this.comparedPeriod.end = addWeeks(this.selectedPeriod.end, -weeks);
      } else if (comparedOption === 'PREVIOUS_YEAR_SAME_PERIOD') {
        this.comparedPeriod = {};
        this.comparedPeriod.start = addYears(new Date(), -1);
        this.comparedPeriod.end = addYears(new Date(), -1);
        const diffDays = differenceInDays(
          this.selectedPeriod.start,
          this.selectedPeriod.end
        );
        this.comparedPeriod.start = addYears(this.selectedPeriod.start, -1);
        this.comparedPeriod.end = addYears(this.selectedPeriod.end, -1);
      } else if (comparedOption === 'PREVIOUS_YEAR_SAME_DAY_OF_WEEK') {
        this.comparedPeriod = {};
        this.comparedPeriod.start = addWeeks(
          new Date(this.selectedPeriod.start),
          -52
        );
        this.comparedPeriod.end = addWeeks(
          new Date(this.selectedPeriod.end),
          -52
        );
      } else if (comparedOption === 'PREVIOUS_YEAR_SAME_WEEK') {
        this.comparedPeriod= {}
        const prevYear =
          this.retailCalendar.find(
            v =>
              v.label.toString() ===
              (
                this.retailYear?.value ??
                new Date(this.selectedPeriod.start).getFullYear() - 1
              ).toString()
          ) || null;
        const weeksToAdd = this.retailWeeks.findIndex(
          v => v === this.retailWeek
        );
        if (prevYear) {
          const startDateRetail = prevYear.startDate;
          const endOfStartDateRetail = moment(
            addDays(new Date(startDateRetail), 6)
          ).format('YYYY-MM-DD');
          // let startDateRetail = prevYear.startDate;

          this.comparedPeriod.start = addWeeks(
            new Date(startDateRetail),
            weeksToAdd
          );
          this.comparedPeriod.end = addWeeks(
            new Date(endOfStartDateRetail),
            weeksToAdd
          );
        } else {
          const currYear = this.retailCalendar.find(
            v => v.label.toString() === this.retailYear.value.toString()
          );
          const startDateRetail = currYear.startDate;
          const endOfStartDateRetail = moment(
            addDays(new Date(startDateRetail), 6)
          ).format('YYYY-MM-DD');
          this.comparedPeriod.start = addWeeks(
            new Date(startDateRetail),
            weeksToAdd - 52
          );
          this.comparedPeriod.end = addWeeks(
            new Date(endOfStartDateRetail),
            weeksToAdd - 52
          );
        }
      } else if (comparedOption === 'previous_year_same_month') {
        this.comparedPeriod = {};
        let retailDetails =
          this.retailCalendar.find(
            v => v.label.toString() === (this.retailYear.value - 1).toString()
          ) || null;
        if (retailDetails) {
          const prevYearMonths = this.getRetailMonths(
            retailDetails.startDate,
            12,
            retailDetails.numberOfWeeks
          );

          const currMonthIndex = this.retailMonths.findIndex(
            v => v === this.retailMonth
          );

          this.comparedPeriod.start = new Date(
            prevYearMonths[currMonthIndex]?.value?.startingPeriod
          );
          this.comparedPeriod.end = new Date(
            prevYearMonths[currMonthIndex]?.value?.endingPeriod
          );
        } else {
          this.comparedPeriod.start = addYears(this.selectedPeriod.start, -1);
          this.comparedPeriod.end = addYears(this.selectedPeriod.end, -1);
        }
      } else if (comparedOption === 'tutuanna_calender') {
        this.specialCalenderMode = true;
        this.calenderMode = 'multiple';
        this.getComparedDatesArray();
      }
    },
    getComparedDatesArray() {
      this.comparedPeriod = {};
      this.comparedPeriod = this.selectedDateRange.map(
        dat => new Date(this.getComparedDate(dat))
      );
    },
    marketingCampaignOptionChange() {
      let val = this.marketingCampaignOption.value;
      this.selectedPeriod = {};
      this.selectedPeriod = {
        start: new Date(val.startDate),
        end: new Date(val.endDate)
      };
    },
    startingPeriodChanged(val) {
      this.setStartingPeriod({ startingPeriod: val });
    }, // startingPeriodChanged
    endingPeriodChanged(val) {
      this.setEndingPeriod({ endingPeriod: val });
    }, // endingPeriodChanged
    startingComparedChanged(val) {
      this.setStartingCompared({ startingCompared: val });
    }, // startingComparedChanged
    endingComparedChanged(val) {
      this.setEndingCompared({ endingCompared: val });
    }, // endingComparedChanged
    refreshPage() {
      this.$emit('refreshPage');
    }, // refreshPage
    submitDate() {
      const dateFilter = {
        startDate: format(this.selectedPeriod.start, 'yyyy-MM-dd'),
        endDate: format(this.selectedPeriod.end, 'yyyy-MM-dd'),
        compareStartDate: format(this.comparedPeriod.start, 'yyyy-MM-dd'),
        compareEndDate: format(this.comparedPeriod.end, 'yyyy-MM-dd')
      };
      this.setdatefilter(dateFilter);
      this.setCalendarDatesToStore({selectedOption: this.dateOptionsSelectedModel.value, comparedOption: this.dateOptionsComparedModel.value, selectedPeriod: this.selectedPeriod, comparedPeriod: this.comparedPeriod})
      
      this.hide();
      this.hide2();
      // this.refreshPage();
    }, // submitDate
    hide() {
      this.$refs.periodFilter1?.hide();
    },
    hide2() {
      this.$refs.periodFilter2?.hide();
    },
    formatDate(date) {
      if (!date) return null;
      let newDate = format(date, 'yyyy-MM-dd');
      const [year, month, day] = newDate.split('-');
      return `${year}-${month}-${day}`;
    },
    getComparedDate(date) {
      if (date && this.compareDates) {
        let dateObj = this.compareDates.filter(dat => dat.date === date);
        if (dateObj.length > 0) {
          return new Date(dateObj[0].comparedDate);
        } else {
          return '';
        }
      }
    },
    dropDownToggled(val) {
      let sStores = localStorage.getItem("storeCodes");
      this.updateCloseCalender(this.stores, sStores?.split(','))
      if(val == 1) {
        this.hide2();
      } else if(val == 2) {
        this.hide()
      }
      // setTimeout(() => {
      //   document.querySelector(".calender-card .vc-weeks .vc-highlight.vc-bg-blue-600")?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
      // }, 1000);
    },
  } // methods
}; // export default
