
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import {exportToPDF} from '@/util/reports/exportopdf.js'
import {downloadPDF} from '@/util/utilFidaFunctions'
import store from '@/store';
import { APICreatePreferencesPulse } from '@/api/kpi';
import {userCheck} from '@/api/fidaApi';

export default {
  data() {
    return {
      showPreviewModal: false,
      isReportSaving: false,
      formFields: {
        numberOfEmployees: '',
        attendanceStaff: '',
        reportTitle: '',
        marketReport: '',
        nextActionPlan: '',
        lastWeekActionPlan: '',
        reviewActionPlan: '',
        createdOn: ''
      },
      confirmSaveReport: false,
      currentUser: 'SuperUser',
      selectedPeriod: null,
      loading: false,
      reportTypeData: {
        daily_report: 'daily',
        weekly_report: 'Weekly',
        monthly_report: 'Monthly'
      }
    };
  },
  computed: {
    ...mapGetters('filter', [
      'getStoreCodesList',
      'getDaysOfWeekList',
      'getPulseStartingPeriod',
      'getPulseEndingPeriod',
      'getPulseStartingCompared',
      'getPulseEndingCompared',
      'getCustomCalenderState',
    ]),
    ...mapState('periodicReport', [
      'currentReport',
      'filterFormState',
      'selectedStoreCode'
    ]),
    ...mapState('user', ['profile']),
    ...mapState('distribution', ['selectedKPIOptions'])
  },
  watch: {
    currentReport(newValue) {
      this.formFields = { ...newValue };
    }
  },
  async created() {
    await this.fetchStores();
    // this.$refs.dailyForm.resetValidation();
  },
  beforeDestroy() {
    this.resetFilterForm();
  },
  methods: {
    ...mapActions('periodicReport', [
      'createPeriodicReport',
      'updatePeriodicReport',
      'getFormLastPeriodValues'
    ]),
    ...mapActions("store", ["fetchStores"]),
    resetForm() {
      this.formFields = {
        numberOfEmployees: null,
        attendanceStaff: null,
        reportTitle: null,
        marketReport: null,
        nextActionPlan: null,
        lastWeekActionPlan: null,
        reviewActionPlan: null,
        createdOn: null
      };
    },
    ...mapMutations('periodicReport', ['setFilterForm', 'resetFilterForm']),
    toggleModal(value) {
      this.showPreviewModal = value;
    },

    removeForm() {
      const element = document.getElementsByClassName('export-form');
      [...element].map(n => n && n.remove());
    },
    createForm() {
      return `
        <div class="export-form">
          <div class="report-view-wrapper">
            <div class="report-view monthly-report">
              <div class="report-title">Monthly Report</div>
              <div class="report-content">
                ${this.formFields.reportTitle}
              </div>
            </div>
            <div class="report-view ">
              <div class="report-title">Market Report</div>
              <div class="report-content">
                ${this.formFields.marketReport}
              </div>
            </div>
          </div>
           <div class="report-view next-plan">
            <div class="report-title">Last Week Action Plan</div>
            <div class="report-content">
              ${this.formFields.lastWeekActionPlan}
            </div>
          </div>
           <div class="report-view next-plan">
            <div class="report-title">Review Action Plan</div>
            <div class="report-content">
              ${this.formFields.reviewActionPlan}
            </div>
          </div>
          <div class="report-view next-plan">
            <div class="report-title">Next Action Plan</div>
            <div class="report-content">
              ${this.formFields.nextActionPlan}
            </div>
          </div>
        </div>
      `;
    },
    createFormNode() {
      const node = document.createElement('div');
      node.innerHTML = this.createForm();
      return node;
    },
    
createFormRequestBody() {
       let formData = new FormData();
      const requestBody = {
        compareEndDate: this.getPulseEndingCompared,
        compareStartDate: this.getPulseStartingCompared,
        endDate: this.getPulseStartingPeriod,
        kpis: this.selectedKPIOptions.join(','),
        startDate: this.getPulseStartingPeriod,
        storeCodes: this.selectedStoreCode,
        reportTitle: this.formFields.reportTitle,
        numberOfEmployees: this.formFields.numberOfEmployees,
        attendanceStaff: this.formFields.attendanceStaff,
        marketReport: this.formFields.marketReport,
        nextActionPlan: this.formFields.nextActionPlan,
        lastWeekActionPlan: this.formFields.lastWeekActionPlan,
        reviewActionPlan: this.formFields.reviewActionPlan,
        fileName: '',
        reportType: "DAILY",

      }; 
  return requestBody;
},
    async submitForm() {
      try {
        this.setFilterForm({
          filterFormKey: 'numberOfEmployees',
          filterFormValue: this.formFields.numberOfEmployees
        });
        this.setFilterForm({
          filterFormKey: 'attendanceStaff',
          filterFormValue: this.formFields.attendanceStaff
        });
        this.setFilterForm({
          filterFormKey: 'reportTitle',
          filterFormValue: this.formFields.reportTitle
        });
        this.setFilterForm({
          filterFormKey: 'marketReport',
          filterFormValue: this.formFields.marketReport
        });
        this.setFilterForm({
          filterFormKey: 'nextActionPlan',
          filterFormValue: this.formFields.nextActionPlan
        });
        this.setFilterForm({
          filterFormKey: 'lastWeekActionPlan',
          filterFormValue: this.formFields.lastWeekActionPlan
        });
        this.setFilterForm({
          filterFormKey: 'reviewActionPlan',
          filterFormValue: this.formFields.reviewActionPlan
        });
  
        this.showPreviewModal = true;

        }
      catch(error){console.log(error);}
    },
    async submitReport() {
      try {
         this.loading = true;
        this.isReportSaving = true;
    //    const pdfFile = await exportToPDF(".report-preview", this.$q.notify);
        const formData = new FormData();
        const storeState = store.state;
        const userID= storeState.user.profile._id;
        const tenentID= storeState.user.profile.tenant._id;
        
        const timestamp = new Date().getTime();

        const objectDatac = this.createFormRequestBody();

        if (this.$route.params.reportId) {
            objectDatac._id = this.$route.params.reportId;
            const res = await this.updatePeriodicReport({
            body: objectDatac,
          });
    
        } else {
          const res = await this.createPeriodicReport({
            body: objectDatac,
          });
        }

        this.showPreviewModal = false;

        this.$q.notify({
          type: 'positive',
          position: 'top',
          message: this.$t('SettingsManagement.report_saved_successfully')
        });
          this.$router.push("/periodic-report-list");
      } catch (error) {
        this.$q.notify({
          type: 'negative',
          position: 'top',
          message: `Error: ${error}`
        });
      } finally {
        this.isReportSaving = false;
        // this.removeForm();
        this.loading = false;
      }
    }
  }
};
