
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import {
  format,
  startOfWeek,
  endOfWeek,
  startOfYesterday,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
  addYears,
  addDays,
  addWeeks,
  addMonths,
  differenceInDays,
  getDay,
  subYears
} from 'date-fns';
import moment from 'moment';
import { handleErrorUI } from '@/util/error';
import DropdownSelect from '@/components/Filter/FilterComponents/DropdownSelect.vue';
import CalenderDays from '@/components/Filter/FilterComponents/CalenderDays.vue';
import DatePicker from '@/components/Filter/FilterComponents/DatePicker.vue';
import { getDayDiffFromMonday, getStartDayOfWeek } from '@/util/calendar.js';

export default {
  data() {
    return {
      citiesList: [],
      masks: {
        weekdays: 'WW'
      },
      attrs: [
        {
          key: 'Holidays',
          highlight: true,
          popover: {
            label: ''
          },
          dates: []
        }
      ],
      retailCalendarMode: false,
      retailMonth: null,
      retailMonths: [],
      retailWeek: '',
      filterRetailWeeks: [],
      retailYear: null,
      monthList: [
        { label: this.$t('FilterBar.January'), value: 'Jan' },
        { label: this.$t('FilterBar.February'), value: 'Feb' },
        { label: this.$t('FilterBar.March'), value: 'Mar' },
        { label: this.$t('FilterBar.April'), value: 'Apr' },
        { label: this.$t('FilterBar.May'), value: 'May' },
        { label: this.$t('FilterBar.June'), value: 'Jun' },
        { label: this.$t('FilterBar.July'), value: 'Jul' },
        { label: this.$t('FilterBar.August'), value: 'Aug' },
        { label: this.$t('FilterBar.September'), value: 'Sep' },
        { label: this.$t('FilterBar.October'), value: 'Oct' },
        { label: this.$t('FilterBar.November'), value: 'Nov' },
        { label: this.$t('FilterBar.December'), value: 'Dec' }
      ],
      retailMonthsOptions: [
        { label: this.$t('FilterBar.January'), value: 'Jan' },
        { label: this.$t('FilterBar.February'), value: 'Feb' },
        { label: this.$t('FilterBar.March'), value: 'Mar' },
        { label: this.$t('FilterBar.April'), value: 'Apr' },
        { label: this.$t('FilterBar.May'), value: 'May' },
        { label: this.$t('FilterBar.June'), value: 'Jun' },
        { label: this.$t('FilterBar.July'), value: 'Jul' },
        { label: this.$t('FilterBar.August'), value: 'Aug' },
        { label: this.$t('FilterBar.September'), value: 'Sep' },
        { label: this.$t('FilterBar.October'), value: 'Oct' },
        { label: this.$t('FilterBar.November'), value: 'Nov' },
        { label: this.$t('FilterBar.December'), value: 'Dec' }
      ],
      retailMonthSelection: '',
      retailWeeks: [],
      retailYears: [],
      demographicReport: false,
      queueReport: false,
      occ: false,
      trafficReport: false,
      scrolled: false,
      calenderMode: 'range',
      specialCalenderMode: false,
      dragValue: null,
      selectedPeriodState: false,
      selectedDateRange: [],
      dateOptionsSelectedModel: {
        label: this.$t('FilterBar.this_week'),
        value: 'THIS_WEEK',
        disable: false
      },
      dateOptionsComparedModel: {
        label: this.$t('FilterBar.previous_period'),
        value: 'PREVIOUS_PERIOD',
        disable: false
      },
      marketingCampaignMode: false,
      marketingCampaignOption: null,
      marketingCampaignOptionList: [],
      dateOptionsSelected: [
        {
          label: this.$t('FilterBar.today'),
          value: 'TODAY'
        },
        {
          label: this.$t('FilterBar.yesterday'),
          value: 'YESTERDAY'
        },

        {
          label: this.$t('FilterBar.this_week'),
          value: 'THIS_WEEK'
        },
        {
          label: this.$t('FilterBar.last_week'),
          value: 'LAST_WEEK'
        },
        {
          label: this.$t('FilterBar.this_month'),
          value: 'THIS_MONTH'
        },
        {
          label: this.$t('FilterBar.last_month'),
          value: 'LAST_MONTH'
        },
        {
          label: this.$t('FilterBar.this_year'),
          value: 'THIS_YEAR'
        },
        {
          label: this.$t('FilterBar.last_year'),
          value: 'LAST_YEAR'
        }
      ],

      dateOptionsCompared: [
        {
          label: this.$t('FilterBar.previous_period'),
          value: 'PREVIOUS_PERIOD'
        },
        {
          label: this.$t('FilterBar.previous_year_same_period'),
          value: 'PREVIOUS_YEAR_SAME_PERIOD'
        },
        {
          label: this.$t('FilterBar.previous_year_same_day_of_week'),
          value: 'PREVIOUS_YEAR_SAME_DAY_OF_WEEK'
        }
      ],
      selectedPeriod: {
        start: new Date(),
        end: new Date()
      },
      comparedPeriod: {
        start: addDays(new Date(), -1),
        end: addDays(new Date(), -1)
      },
      lastTimeStamp: Date.now(),
      currentTargetDate: moment(Date.now()).format('YYYY-MM-DD'),
      custom445Dates: {},
      selectEnabled: [],
      selectedDateId: '',
      lastDateScroll: 0,
      datePickerRows: 18,
      isScrolling: false,
      lastStoreTime: 0,
      startDayFromMonday: 0,
      startDayOfWeek: 0
    };
  },
  computed: {
    ...mapGetters('user', ['getLocale']),
    ...mapState('user', ['profile', 'startOfWeek', 'defaultSettings']),
    ...mapState('filter', ['filter', 'holidays', 'retailCalendar', 'storeCodes']),
    ...mapState('store', ['stores']),
    ...mapState('comparison', ['marketingCampaign', 'compareDates']),
    // startingDateFormatted(){
    //   return moment(this.filter.startingPeriod).format('YYYY/mm/dd')
    // },
    comparedPeriodText() {
      const { startingCompared, endingCompared } = this.filter;
      return `
            ${this.getFormattedDate(startingCompared)} (${this.getFullDay(
        startingCompared
      )}) ~ ${this.getFormattedDate(endingCompared)}(${this.getFullDay(
        endingCompared
      )})
            (${this.getDifferenceDays(startingCompared, endingCompared) +
              1}${this.$t('unit.day')})
          `;
    },
    tutuannaMode() {
      return this.dateOptionsComparedModel?.value === 'tutuanna_calender';
    },
    selectDragAttribute() {
      return {
        popover: {
          visibility: this.tutuannaMode ? 'hover' : 'none',
          isInteractive: false // * Defaults to true when using slot
        }
      };
    },
    dragComparedValue: function() {
      let value = '';
      if (this.dragValue && this.compareDates) {
        value = this.getComparedDate(this.formatDate(this.dragValue));
        return this.formatDate(value);
      } else {
        return value;
      }
    },
    locationEnable() {
      return this.defaultSettings.locationFilter;
    },
    numberfRetailWeeks() {
      return this.retailCalendar.find(
        v => v.label.toString() === this.retailYear.value.toString()
      )?.numberOfWeeks;
    },
    startDateOfRetailYear() {
      return this.retailCalendar.find(
        v => v.label.toString() === this.retailYear?.value.toString()
      )?.startDate;
    },
   
  },
  watch: {
    stores(newData){
      let sStores = localStorage.getItem("storeCodes");
      this.updateCloseCalender(newData, sStores?.split(','))
    },
    retailYear() {
      this.onRetailYearChange(this.retailYear.value, true);
      let num = this.retailCalendar.find(
        v => v.label.toString() === this.retailYear.value.toString()
      )?.numberOfWeeks;
      let startDateRetail = this.retailCalendar.find(
        v => v.label.toString() === this.retailYear.value.toString()
      )?.startDate;
      this.retailMonthSelection = {
        label: this.$t(`FilterBar.${moment(startDateRetail).format('MMMM')}`),
        value: moment(startDateRetail).format('MMM')
      };

      let monthIndex = this.monthList.findIndex(
        v => v.value === this.retailMonthSelection.value
      );
      this.retailMonthsOptions = [
        ...this.monthList.slice(monthIndex, 12),
        ...this.monthList.slice(0, monthIndex)
      ];
      // this.retailMonthSelection = { label: this.$t('January'), value: 'Jan' };
      this.getRetailWeeks(startDateRetail, 12, num);
    },
    retailWeek() {
      this.changeWeek();
    },
    retailMonth() {
      this.changeMonth();
    },
    retailMonthSelection() {
      this.filterRetailWeeks = this.retailWeeks.filter(v =>
        v.label.includes(this.getSelectedMonth(this.retailMonthSelection.value))
      );
      this.retailWeek = this.filterRetailWeeks[0];
      this.changeTempCalender();
    },
    marketingCampaign() {
      if (this.marketingCampaign && this.marketingCampaign.length > 0) {
        this.dateOptionsSelected = [
          ...this.dateOptionsSelected,
          {
            label: this.$t('FilterBar.marketing_campaigns'),
            value: 'marketing_campaigns'
          }
        ];
      }
    },
    compareDates() {
      if (this.compareDates && this.compareDates.length > 0) {
        this.dateOptionsCompared = [
          ...this.dateOptionsCompared,
          {
            label: this.$t('FilterBar.special_calendar'),
            value: 'tutuanna_calender'
          }
        ];
      }
    },
    holidays() {
      this.attrs = [
       
        ...this.holidays.map(v => ({
          key: 'Holidays',
          // highlight: true,
          popover: {
            label: v.value,
            labelClass: 'white-text',
            labelStyle: { color: 'white' },
            class: 'white-text'
          },
          dates: v.date,
          bar: {
            color: 'red',
            class: 'my-dot-class'
          }
        })),
        ...this.attrs,
      ];
    },
    'filter.daysOfWeek': {
      handler: 'refreshPage'
    },
    'filter.storeCodes':function (newData) {
      // handler: 'refreshPage'
      this.updateCloseCalender(this.stores, newData)
      this.refreshPage()
    },
    $router(to, from) {
      this.occ = this.$router.history.current.name === 'occupancy_report';
      this.trafficReport =
        this.$router.history.current.name === 'traffic_report';
      this.queueReport = this.$router.history.current.name === 'queue_report';
      this.demographicReport =
        this.$router.history.current.name === 'demographics-report';
    },
    selectedPeriod: function() {
      this.getSelectedDateRange();
      if (this.tutuannaMode) {
        this.comparedPeriod = this.selectedDateRange.map(
          dat => new Date(this.getComparedDate(dat))
        );
      } else {
        try {
          if (this.dateOptionsComparedModel.value === 'PREVIOUS_PERIOD') {
            const diffDays = differenceInDays(
              this.selectedPeriod.start,
              this.selectedPeriod.end
            );
            const diffDaysPositive =
              diffDays < 0 ? (diffDays - 1) * -1 : diffDays + 1;
            const weeks = Math.ceil((diffDaysPositive / 7).toFixed(2));
            this.setComparedPeriod(
              addWeeks(this.selectedPeriod.start, -weeks),
              addWeeks(this.selectedPeriod.end, -weeks)
            );
          } else if (
            this.dateOptionsComparedModel.value === 'PREVIOUS_YEAR_SAME_PERIOD'
          ) {
            this.setComparedPeriod(
              addYears(new Date(this.selectedPeriod.start), -1),
              addYears(new Date(this.selectedPeriod.end), -1)
            );
          } else if (
            this.dateOptionsComparedModel.value ===
            'PREVIOUS_YEAR_SAME_DAY_OF_WEEK'
          ) {
            const diffDays = differenceInDays(
              this.selectedPeriod.start,
              this.selectedPeriod.end
            );
            let st = addDays(new Date(this.selectedPeriod.start), -365);
            let et = addDays(new Date(this.selectedPeriod.end), -365);
            this.setComparedPeriod(st, et);
          } else if (
            this.dateOptionsComparedModel.value === 'PREVIOUS_YEAR_SAME_WEEK'
          ) {
            const prevYear =
              this.retailCalendar.find(
                v =>
                  v.label.toString() ===
                  (
                    this.retailYear?.value ??
                    new Date(this.selectedPeriod.start).getFullYear() - 1
                  ).toString()
              ) || null;
            const weeksToAdd = this.retailWeeks.findIndex(
              v => v === this.retailWeek
            );
            if (prevYear) {
              const startDateRetail = prevYear.startDate;
              const endOfStartDateRetail = moment(
                addDays(new Date(startDateRetail), 6)
              ).format('YYYY-MM-DD');
              // let startDateRetail = prevYear.startDate;

              let st = addWeeks(new Date(startDateRetail), weeksToAdd);
              let et = addWeeks(new Date(endOfStartDateRetail), weeksToAdd);
              this.setComparedPeriod(st, et);
            } else {
              const currYear = this.retailCalendar.find(
                v => v.label.toString() === this.retailYear.value.toString()
              );
              const startDateRetail = currYear.startDate;
              const endOfStartDateRetail = moment(
                addDays(new Date(startDateRetail), 6)
              ).format('YYYY-MM-DD');
              let st = addWeeks(new Date(startDateRetail), weeksToAdd - 52);
              let et = addWeeks(
                new Date(endOfStartDateRetail),
                weeksToAdd - 52
              );
              this.setComparedPeriod(st, et);
            }
          } else if (
            this.dateOptionsComparedModel.value === 'previous_year_same_month'
          ) {
            let retailDetails =
              this.retailCalendar.find(
                v =>
                  v.label.toString() === (this.retailYear.value - 1).toString()
              ) || null;
            if (retailDetails) {
              const prevYearMonths = this.getRetailMonths(
                retailDetails.startDate,
                12,
                retailDetails.numberOfWeeks
              );

              const currMonthIndex = this.retailMonths.findIndex(
                v => v === this.retailMonth
              );

              let st = new Date(
                prevYearMonths[currMonthIndex]?.value?.startingPeriod
              );
              let et = new Date(
                prevYearMonths[currMonthIndex]?.value?.endingPeriod
              );
              this.setComparedPeriod(st, et);
            } else {
              this.setComparedPeriod(
                addYears(this.selectedPeriod.start, -1),
                addYears(this.selectedPeriod.end, -1)
              );
            }
          }
        } catch (e) {
          // console.log(e);
        }
      }
    },
    dateOptionsSelectedModel(val) {
      // if (val.value === "retail_calendar") {
      //   this.dateOptionsCompared = [
      //     ...this.dateOptionsCompared,
      //     {
      //       label: this.$t("FilterBar.previous_year_same_week"),
      //       value: "PREVIOUS_YEAR_SAME_WEEK",
      //     },
      //   ];
      // } else {
      //   this.dateOptionsCompared = this.dateOptionsCompared.filter(
      //     (v) => v.value !== "PREVIOUS_YEAR_SAME_WEEK"
      //   );
      //   this.dateOptionsComparedModel = {
      //     label: this.$t("FilterBar.previous_period"),
      //     value: "PREVIOUS_PERIOD",
      //   };
      // }
      // if (val.value === "retail_calendar_monthly") {
      //   this.dateOptionsCompared = [
      //     ...this.dateOptionsCompared,
      //     {
      //       label: this.$t("FilterBar.previous_year_same_month"),
      //       value: "previous_year_same_month",
      //     },
      //   ];
      // } else {
      //   this.dateOptionsCompared = this.dateOptionsCompared.filter(
      //     (v) => v.value !== "previous_year_same_month"
      //   );
      //   this.dateOptionsComparedModel = {
      //     label: this.$t("FilterBar.previous_period"),
      //     value: "PREVIOUS_PERIOD",
      //   };
      // }
      this.getSelectedDateRange();
      if (this.tutuannaMode) {
        this.comparedPeriod = this.selectedDateRange.map(
          dat => new Date(this.getComparedDate(dat))
        );
      } else {
        if (this.dateOptionsComparedModel.value === 'PREVIOUS_PERIOD') {
          const diffDays = differenceInDays(
            this.selectedPeriod.start,
            this.selectedPeriod.end
          );
          const diffDaysPositive =
            diffDays < 0 ? (diffDays - 1) * -1 : diffDays + 1;
          const weeks = Math.ceil((diffDaysPositive / 7).toFixed(2));
          this.setComparedPeriod(
            addWeeks(this.selectedPeriod.start, -weeks),
            addWeeks(this.selectedPeriod.end, -weeks)
          );
        } else if (
          this.dateOptionsComparedModel.value === 'PREVIOUS_YEAR_SAME_PERIOD'
        ) {
          let st = moment(new Date(this.selectedPeriod.start))
            .subtract(364, 'days')
            .startOf('day');

          let et = moment(new Date(this.selectedPeriod.end))
            .subtract(364, 'days')
            .startOf('day');
          this.setComparedPeriod(st, et);
        } else if (
          this.dateOptionsComparedModel.value ===
          'PREVIOUS_YEAR_SAME_DAY_OF_WEEK'
        ) {
          const diffDays = differenceInDays(
            this.selectedPeriod.start,
            this.selectedPeriod.end
          );
          let st = addDays(new Date(this.selectedPeriod.start), -364);
          let et = addDays(new Date(this.selectedPeriod.end), -364);
          this.setComparedPeriod(st, et);
        }
      }
      this.changeTempCalender()
    },
    marketingCampaignOption: function() {
      if (!this.tutuannaMode && this.marketingCampaignMode) {
        if (this.dateOptionsComparedModel.value === 'PREVIOUS_PERIOD') {
          const diffDays = differenceInDays(
            this.selectedPeriod.start,
            this.selectedPeriod.end
          );
          const diffDaysPositive =
            diffDays < 0 ? (diffDays - 1) * -1 : diffDays + 1;
          const weeks = Math.ceil((diffDaysPositive / 7).toFixed(2));
          let st = addWeeks(this.selectedPeriod.start, -weeks);
          let et = addWeeks(this.selectedPeriod.end, -weeks);
          this.setComparedPeriod(st, et);
        } else if (
          this.dateOptionsComparedModel.value === 'PREVIOUS_YEAR_SAME_PERIOD'
        ) {
          let st = moment(new Date(this.selectedPeriod.start))
            .subtract(364, 'days')
            .startOf('day');

          let et = moment(new Date(this.selectedPeriod.end))
            .subtract(364, 'days')
            .startOf('day');
          this.setComparedPeriod(st, et);
        } else if (
          this.dateOptionsComparedModel.value ===
          'PREVIOUS_YEAR_SAME_DAY_OF_WEEK'
        ) {
          const diffDays = differenceInDays(
            this.selectedPeriod.start,
            this.selectedPeriod.end
          );
          let st = addDays(new Date(this.selectedPeriod.start), -364);
          let et = addDays(new Date(this.selectedPeriod.end), -364);
          this.setComparedPeriod(st, et);
        }
      }
    }
  },
  async created() {
    // localStorage.removeItem("weather");
    // localStorage.removeItem("weatherCompare");
    // //
    // localStorage.removeItem("weatherHourly");
    // localStorage.removeItem("weatherHourlyC");
    this.fetchWeather(this.citiesList);
    this.firstTimeCheck();
    if (this.retailCalendar.length > 0) {
      this.setCustom445DateRange({
        custom445Dates: this.custom445Dates
      });
      this.dateOptionsSelected = [
        ...this.dateOptionsSelected,
        {
          label: this.$t('FilterBar.retail_calendar_monthly'),
          value: 'retail_calendar_monthly'
        },
        {
          label: this.$t('FilterBar.retail_calendar'),
          value: 'retail_calendar'
        },
        {
          label: this.$t('custom_range'),
          value: 'CUSTOM',
          disable: true
        }
      ];

      this.retailYears = await this.retailCalendar.map(v => ({
        label: v.label,
        value: v.label
      }));
    }
    let sStores = localStorage.getItem("storeCodes");
    this.updateCloseCalender(this.stores, sStores?.split(','))
    this.init();
    this.refreshPage();
  },
  methods: {
    ...mapActions('weather', ['getWeather', 'getCompareWeather']),
    ...mapMutations('filter', [
      'setStartingPeriod',
      'setCalendarDatesToStore',
      'setEndingPeriod',
      'setStartingCompared',
      'setEndingCompared',
      'setSelectedPeriod',
      'setCustomCalenderFlag',
      'setCustom445DateRange',
      'setHasSessionListnened',
      'firstTimeCheck'
    ]), // mapMutations
    ...mapGetters('filter', ['getHasSessionListnend']),
    ...mapActions('comparison', [
      'fetchCompareDates',
      'fetchMarketingCampaign',
      'fetchHolidays',
      'fetchKPIMetrics'
    ]),
    ...mapMutations('periodicReport', [
      'setLastTimeStamp',
      'setMonthlyPoints',
      'setYearlyPoints'
    ]),
    ...mapActions('filter', ['fetchHolidays']),
    updateCloseCalender(data, selectedStores){
      const dataAccessStores = data?.dataAccessStores;
      if(dataAccessStores){
        const filteredStores = dataAccessStores.filter(store => selectedStores?.includes(store.storeNumber));
        const filteredTimings = filteredStores.flatMap(store => store.storeTimings.filter(timing => timing.dates));
        const formattedData = filteredTimings.flatMap(timing => timing.dates.map(date => (
          {
            "key": "Closed",
            "popover": {
                "label": timing.day,
                "labelClass": "white-text",
                "labelStyle": {
                    "color": "white"
                },
                "class": "white-text"
            },
            "dates": date,
            "bar": {
              style: {
                  backgroundColor: 'blue',
                },
                // "color": "blue",
                "class": "blue-bar"
            }
        })));
        const filter = this.attrs.filter(item => item.key != "Closed");
        this.attrs = [...filter, ...formattedData];
      }
    },
    changeTempCalender(){
      setTimeout(() => {
        if (!this.retailYear.value) return;
        if (this.dateOptionsSelectedModel.value == 'retail_calendar_monthly') {
          this.changeMonth();
        }
        if (this.dateOptionsSelectedModel.value == 'retail_calendar') {
          this.changeWeek();
        }
      }, 300);
    },
    changeMonth() {
      if (
        new Date(this.retailMonth?.value?.startingPeriod) < new Date() &&
        new Date(this.retailMonth?.value?.endingPeriod) < new Date()
      ) {
        this.$set(
          this.selectedPeriod,
          'start',
          new Date(this.retailMonth?.value?.startingPeriod)
        );
        this.$set(
          this.selectedPeriod,
          'end',
          new Date(this.retailMonth?.value?.endingPeriod)
        );
      } else {
        this.$q.notify({
          type: 'negative',
          position: 'top',
          message: `Please select range lower than present day`
        });
      }
    },
    changeWeek() {
      if (
        new Date(this.retailWeek?.value?.startingPeriod) < new Date() &&
        new Date(this.retailWeek?.value?.endingPeriod) < new Date()
      ) {
        this.$set(
          this.selectedPeriod,
          'start',
          new Date(this.retailWeek?.value?.startingPeriod)
        );
        this.$set(
          this.selectedPeriod,
          'end',
          new Date(this.retailWeek?.value?.endingPeriod)
        );
      } else {
        this.$q.notify({
          type: 'negative',
          position: 'top',
          message: `Please select range lower than present day`
        });
      }
    },
    setSelectedPeriod(start, end) {
      let s = start,
        e = end;
      if (Object.keys(start).length) {
        s = start.start;
        e = start.end;
      }
      if (s) {
        this.$set(this.selectedPeriod, 'start', s);
      }
      if (e) {
        this.$set(this.selectedPeriod, 'end', e);
      }

      this.dateOptionsComparedModelChangedNew();
    },
    setComparedPeriod(start, end) {
      let s = start,
        e = end;
      if (Object.keys(start).length) {
        s = start.start;
        e = start.end;
      }
      if (s) {
        this.$set(this.comparedPeriod, 'start', s);
      }
      if (e) {
        this.$set(this.comparedPeriod, 'end', e);
      }
    },
    async init() {
      this.startDayFromMonday = getDayDiffFromMonday();
      this.startDayOfWeek = getStartDayOfWeek();
      // retail selected year
      var currentYear = moment(this.currentTargetDate).format('YYYY');
      if (this.retailYear?.value) {
        currentYear = this.retailYear.value;
      }
      this.onRetailYearChange(currentYear, false);

      this.setSelectedPeriod(
        new Date(this.filter.startingPeriod),
        new Date(this.filter.endingPeriod)
      );
      this.setComparedPeriod(
        new Date(this.filter.startingCompared),
        new Date(this.filter.endingCompared)
      );

      this.dateOptionsSelected.forEach(data => {
        if (data.value == this.filter.selectedOption) {
          let nVal = JSON.parse(JSON.stringify(data));
          this.dateOptionsSelectedModel = {};
          this.dateOptionsSelectedModel = nVal;
          // this.$set(this.dateOptionsSelectedModel, "value", nVal.value);
          // this.$set(this.dateOptionsSelectedModel, "label", nVal.label);
          // this.$set(this.dateOptionsSelectedModel, "disable", nVal.disable || false);
        }
      });
      if (
        this.filter.selectedOption == 'CUSTOM_RANGE' ||
        this.filter.selectedOption == 'CUSTOM'
      ) {
        this.onDayClick();
      } else {
        this.dateOptionsSelectedModelChangedNew(this.filter.selectedOption);
      }
      this.dateOptionsCompared.forEach(data => {
        if (data.value == this.filter.comparedOption) {
          let nVal = JSON.parse(JSON.stringify(data));
          this.dateOptionsComparedModel = {};
          this.dateOptionsComparedModel = nVal;
          // this.$set(this.dateOptionsComparedModel, "value", data.value);
          // this.$set(this.dateOptionsComparedModel, "label", data.label);
          // this.$set(this.dateOptionsComparedModel, "disable", data.disable || false);
        }
      });
      if (this.filter.comparedOption == 'CUSTOM') {
        this.onDayClickCompare();
      } else {
        this.dateOptionsComparedModelChangedNew(this.filter.comparedOption);
      }
      this.occ = this.$router.history.current.name === 'occupancy_report';
      this.trafficReport =
        this.$router.history.current.name === 'traffic_report';
      this.queueReport = this.$router.history.current.name === 'queue_report';
      this.demographicReport =
        this.$router.history.current.name === 'demographics-report';
      try {
        await this.fetchHolidays();
        await this.fetchCompareDates();
        await this.fetchMarketingCampaign();
        // await this.fetchKPIMetrics();
        this.getSelectedDateRange();
      } catch (e) {
        handleErrorUI(e);
      }
      this.marketingCampaignOptionList = this.marketingCampaign
        ? this.marketingCampaign.map(mc => {
            return {
              label: mc.campaignName,
              value: {
                startDate: mc.startDate,
                endDate: mc.endDate
              }
            };
          })
        : [];
      this.setCalendarDatesToStore({
        selectedOption: this.dateOptionsSelectedModel.value,
        comparedOption: this.dateOptionsComparedModel.value,
        selectedPeriod: this.selectedPeriod,
        comparedPeriod: this.comparedPeriod
      });
    },
    onDayClick() {
      this.dateOptionsSelectedModel = {
        label: this.$t('custom_range'),
        value: 'CUSTOM',
        disable: true
      };
    },
    onDayClickCompare() {
      this.dateOptionsComparedModel = {
        label: this.$t('custom_range'),
        value: 'CUSTOM',
        disable: true
      };
    },
    selectedCities(citiesList) {
      this.citiesList = citiesList;
      this.$store.commit('weather/setCitiesListWeather', citiesList);
      this.fetchWeather(this.citiesList);
      //  this.fetchWeather([{cityId:'ä¸­å¤®åŒº'}])
    },
    fetchWeather(cityName) {
      // var w = localStorage.getItem("weather");
      // var wC = localStorage.getItem("weatherCompare");
      // //
      // var wH = localStorage.getItem("weatherHourly");
      // var wCh = localStorage.getItem("weatherHourlyC");
      setTimeout(() => {
        const startDate = moment(this.filter.startingPeriod);
        const endDate = moment(this.filter.endingPeriod);
        const startDateComp = moment(this.filter.startingCompared);
        const endDateComp = moment(this.filter.endingCompared);
        if (cityName && !cityName.length) return;
        // var check =
        //   this.filter.startingPeriod == this.filter.startingCompared &&
        //   this.filter.endingPeriod == this.filter.endingCompared;
        // if (!w || w == "undefined") {
        this.getWeather({ startDate, endDate, cityName })
          .then(res => {})
          .catch(err => {
            console.log(err, 'error');
          });
        // } else {
        //   this.$store.commit("weather/weather", JSON.parse(w));
        //   this.$store.commit("weather/weatherHourly", JSON.parse(wH));
        //   if (check) {
        //     this.$store.commit("weather/setComparedWeather", JSON.parse(JSON.parse(w)));
        //     this.$store.commit("weather/setComparedWeatherHourly", JSON.parse(wCh));
        //   }
        // }
        // if ((!check && !wC) || (!check && wC == "undefined")) {
        this.getCompareWeather({
          startDate: startDateComp,
          endDate: endDateComp,
          cityName
        })
          .then(res => {})
          .catch(err => {
            console.log(err, 'error');
          });
        // } else {
        //   this.$store.commit("weather/setComparedWeather", JSON.parse(wC));
        //   this.$store.commit("weather/setComparedWeatherHourly", JSON.parse(wCh));
        // }
      }, 1000);
    },
    getSelectedMonth(val) {
      if (val.includes('Jan')) return '/01/';
      else if (val.includes('Feb')) return '/02/';
      else if (val.includes('Mar')) return '/03/';
      else if (val.includes('Apr')) return '/04/';
      else if (val.includes('May')) return '/05/';
      else if (val.includes('Jun')) return '/06/';
      else if (val.includes('Jul')) return '/07/';
      else if (val.includes('Aug')) return '/08/';
      else if (val.includes('Sep')) return '/09/';
      else if (val.includes('Oct')) return '/10/';
      else if (val.includes('Nov')) return '/11/';
      else if (val.includes('Dec')) return '/12/';
    },
    getRetailMonths(startingDateVal, noOfMonths, noOfWeeks = 52) {
      let startingMonth = new Date(startingDateVal);
      let monthsVal = [];
      for (let i = 0; i < noOfMonths; i++) {
        let monthStart = moment(startingMonth).format('YYYY-MM-DD');
        let monthEnd = moment(
          addDays(addWeeks(startingMonth, (i + 1) % 3 === 0 ? 5 : 4), -1)
        ).format('YYYY-MM-DD');
        if (
          this.currentTargetDate >= monthStart &&
          this.currentTargetDate <= monthEnd
        ) {
          this.custom445Dates['monthStart'] = monthStart;
          this.custom445Dates['monthEnd'] = monthEnd;
        }
        startingMonth = addWeeks(startingMonth, (i + 1) % 3 === 0 ? 5 : 4);
        monthsVal.push({
          label:
            moment(monthStart).format('YYYY/MM/DD') +
            ' - ' +
            moment(monthEnd).format('YYYY/MM/DD'),
          value: {
            startingPeriod: monthStart,
            endingPeriod: monthEnd
          }
        });
      }
      if (noOfWeeks === '53') {
        monthsVal[10].value.endingPeriod = moment(
          addWeeks(new Date(monthsVal[10].value.endingPeriod), 1)
        ).format('YYYY-MM-DD');
        monthsVal[10].label =
          monthsVal[10]?.value?.startingPeriod.replace('-', '/') +
          ' - ' +
          monthsVal[10]?.value?.endingPeriod.replace('-', '/');
        monthsVal[11].value.startingPeriod = moment(
          addWeeks(new Date(monthsVal[11].value.startingPeriod), 1)
        ).format('YYYY-MM-DD');
        monthsVal[11].value.endingPeriod = moment(
          addWeeks(new Date(monthsVal[11].value.endingPeriod), 1)
        ).format('YYYY-MM-DD');
        monthsVal[11].label =
          monthsVal[11].value?.startingPeriod.replace('-', '/') +
          ' - ' +
          monthsVal[11].value?.endingPeriod.replace('-', '/');
      }
      return monthsVal;
    },
    getRetailWeeks(startingDateVal, noOfMonths, noOfWeeks, updateValue) {
      let startingDate = startingDateVal ? new Date(startingDateVal) : null;

      this.retailWeeks = [];
      this.retailMonths = this.getRetailMonths(
        startingDateVal,
        noOfMonths,
        noOfWeeks
      );
      if (updateValue) {
        this.retailMonth = startingDate ? this.retailMonths[0] : null;
      }
      for (let i = 0; i < noOfWeeks; i++) {
        let weekStart = moment(startingDate).format('YYYY-MM-DD');
        let weekEnd = moment(addDays(addWeeks(startingDate, 1), -1)).format(
          'YYYY-MM-DD'
        );
        if (
          this.currentTargetDate >= weekStart &&
          this.currentTargetDate <= weekEnd
        ) {
          this.custom445Dates['weekStart'] = weekStart;
          this.custom445Dates['weekEnd'] = weekEnd;
        }
        const weekVal = {
          label:
            moment(weekStart).format('YYYY/MM/DD') +
            ' - ' +
            moment(weekEnd).format('YYYY/MM/DD'),
          value: {
            startingPeriod: weekStart,
            endingPeriod: weekEnd
          }
        };
        startingDate = addWeeks(startingDate, 1);
        this.retailWeeks.push(weekVal);
      }
    },
    onRetailYearChange(retailYear, isValue) {
      let num = this.retailCalendar.find(
        v => v.label.toString() === retailYear.toString()
      )?.numberOfWeeks;
      let startDateRetail = this.retailCalendar.find(
        v => v.label.toString() === retailYear.toString()
      )?.startDate;
      if (isValue) {
        this.retailMonthSelection = {
          label: this.$t(`FilterBar.${moment(startDateRetail).format('MMMM')}`),
          value: moment(startDateRetail).format('MMM')
        };
      }

      let monthIndex = this.monthList.findIndex(
        v => v.value === this.retailMonthSelection.value
      );
      this.retailMonthsOptions = [
        ...this.monthList.slice(monthIndex, 12),
        ...this.monthList.slice(0, monthIndex)
      ];
      // this.retailMonthSelection = { label: this.$t('January'), value: 'Jan' };
      this.getRetailWeeks(startDateRetail, 12, num, isValue);
    },
    getSelectedDateRange() {
      let dates = [];
      if (this.selectedPeriod?.start && this.selectedPeriod?.end) {
        let startDate = this.selectedPeriod.start;
        while (startDate <= this.selectedPeriod.end) {
          dates.push(startDate);
          startDate = addDays(startDate, 1);
        }
        this.selectedDateRange = dates.map(dat => this.formatDate(dat));

        //this.defaultSettings
      }
    },
    dateOptionsSelectedModelChanged() {
      const selectedOption = this.dateOptionsSelectedModel.value;
      this.marketingCampaignMode = false;
      this.retailCalendarMode = false;
      if (selectedOption === 'TODAY') {
        this.setSelectedPeriod(new Date(), new Date());
      } else if (selectedOption === 'YESTERDAY') {
        const yesterday = startOfYesterday();
        this.setSelectedPeriod(yesterday, yesterday);
      } else if (selectedOption === 'THIS_WEEK') {
        let st = startOfWeek(new Date(), {
          weekStartsOn: this.startDayOfWeek == '-1' ? 0 : this.startDayOfWeek
        });
        this.setSelectedPeriod(st, new Date());
      } else if (selectedOption === 'LAST_WEEK') {
        const d = addWeeks(new Date(), -1);
        let st = startOfWeek(d, {
          weekStartsOn: this.startDayOfWeek == '-1' ? 0 : this.startDayOfWeek
        });
        let et = addDays(st, 6);
        this.setSelectedPeriod(st, et);
      } else if (selectedOption === 'THIS_MONTH') {
        this.setSelectedPeriod(startOfMonth(new Date()), new Date());
      } else if (selectedOption === 'LAST_MONTH') {
        this.setSelectedPeriod(
          startOfMonth(addMonths(new Date(), -1)),
          endOfMonth(addMonths(new Date(), -1))
        );
      } else if (selectedOption === 'THIS_YEAR') {
        this.setSelectedPeriod(startOfYear(new Date()), new Date());
      } else if (selectedOption === 'LAST_YEAR') {
        this.setSelectedPeriod(
          addYears(startOfYear(new Date()), -1),
          addYears(endOfYear(new Date()), -1)
        );
      } else if (selectedOption === 'marketing_campaigns') {
        this.marketingCampaignMode = true;
      } else if (selectedOption === 'retail_calendar') {
        this.retailCalendarMode = true;
      } else if (selectedOption === 'retail_calendar_monthly') {
        this.retailCalendarMode = true;
      }
      // this.dateOptionsComparedModelChanged();
      this.dateOptionsComparedModelChangedNew();
    },
    dateOptionsSelectedModelChangedNew(res) {
      let selectedOption;
      if (res && Object.keys(res).length == 2) {
        selectedOption = res.value;
      } else {
        selectedOption = res;
      }
      this.marketingCampaignMode = false;
      this.retailCalendarMode = false;
      if (selectedOption === 'TODAY') {
        this.setSelectedPeriod(new Date(), new Date());
      } else if (selectedOption === 'YESTERDAY') {
        const yesterday = startOfYesterday();
        this.setSelectedPeriod(yesterday, yesterday);
      } else if (selectedOption === 'THIS_WEEK') {
        let st = startOfWeek(new Date(), {
          weekStartsOn: this.startDayOfWeek == '-1' ? 0 : this.startDayOfWeek
        });
        this.setSelectedPeriod(st, new Date());
      } else if (selectedOption === 'LAST_WEEK') {
        const d = addWeeks(new Date(), -1);
        let st = startOfWeek(d, {
          weekStartsOn: this.startDayOfWeek == '-1' ? 0 : this.startDayOfWeek
        });
        let et = addDays(st, 6);
        this.setSelectedPeriod(st, et);
      } else if (selectedOption === 'THIS_MONTH') {
        this.setSelectedPeriod(startOfMonth(new Date()), new Date());
      } else if (selectedOption === 'LAST_MONTH') {
        this.setSelectedPeriod(
          startOfMonth(addMonths(new Date(), -1)),
          endOfMonth(addMonths(new Date(), -1))
        );
      } else if (selectedOption === 'THIS_YEAR') {
        this.setSelectedPeriod(startOfYear(new Date()), new Date());
      } else if (selectedOption === 'LAST_YEAR') {
        this.setSelectedPeriod(
          addYears(startOfYear(new Date()), -1),
          addYears(endOfYear(new Date()), -1)
        );
      } else if (selectedOption === 'marketing_campaigns') {
        this.marketingCampaignMode = true;
      } else if (selectedOption === 'retail_calendar') {
        this.retailCalendarMode = true;
      } else if (selectedOption === 'retail_calendar_monthly') {
        this.retailCalendarMode = true;
      }
      this.dateOptionsComparedModelChangedNew();
      //  this.selectedPeriodCheck =1;
    },
    dateOptionsComparedModelChanged() {
      this.specialCalenderMode = false;
      // const selectedOption = this.dateOptionsSelectedModel.value;
      const comparedOption = this.dateOptionsComparedModel.value;
      this.calenderMode = 'range';
      if (comparedOption === 'PREVIOUS_PERIOD') {
        const diffDays = differenceInDays(
          this.selectedPeriod.start,
          this.selectedPeriod.end
        );
        const diffDaysPositive =
          diffDays < 0 ? (diffDays - 1) * -1 : diffDays + 1;
        const weeks = Math.ceil(Number((diffDaysPositive / 7).toFixed(2)));
        this.setComparedPeriod(
          addWeeks(this.selectedPeriod.start, -weeks),
          addWeeks(this.selectedPeriod.end, -weeks)
        );
      } else if (comparedOption === 'PREVIOUS_YEAR_SAME_PERIOD') {
        const previousYearStartDate = subYears(
          new Date(this.selectedPeriod.start),
          1
        );
        const previousYearEndDate = subYears(
          new Date(this.selectedPeriod.end),
          1
        );
        this.setComparedPeriod(previousYearStartDate, previousYearEndDate);
        // const diffDays = differenceInDays(
        //   this.selectedPeriod.start,
        //   this.selectedPeriod.end
        // );
        // this.setComparedPeriod(addDays(new Date(this.selectedPeriod.start), -364), addDays(new Date(this.selectedPeriod.end), -364))
      }
      //PREVIOUS_YEAR_SAME_DAY_OF_WEEK
      else if (comparedOption === 'PREVIOUS_YEAR_SAME_DAY_OF_WEEK') {
        const diffDays = differenceInDays(
          this.selectedPeriod.start,
          this.selectedPeriod.end
        );
        this.setComparedPeriod(
          addDays(new Date(this.selectedPeriod.start), -364),
          addDays(new Date(this.selectedPeriod.end), -364)
        );
      } else if (comparedOption === 'PREVIOUS_YEAR_SAME_WEEK') {
        const prevYear =
          this.retailCalendar.find(
            v =>
              v.label.toString() ===
              (
                this.retailYear?.value ??
                new Date(this.selectedPeriod.start).getFullYear() - 1
              ).toString()
          ) || null;
        const weeksToAdd = this.retailWeeks.findIndex(
          v => v === this.retailWeek
        );
        if (prevYear) {
          const startDateRetail = prevYear.startDate;
          const endOfStartDateRetail = moment(
            addDays(new Date(startDateRetail), 6)
          ).format('YYYY-MM-DD');

          this.setComparedPeriod(
            addWeeks(new Date(startDateRetail), weeksToAdd),
            addWeeks(new Date(endOfStartDateRetail), weeksToAdd)
          );
        } else {
          const currYear = this.retailCalendar.find(
            v => v.label.toString() === this.retailYear.value.toString()
          );
          const startDateRetail = currYear.startDate;
          const endOfStartDateRetail = moment(
            addDays(new Date(startDateRetail), 6)
          ).format('YYYY-MM-DD');
          let st = addWeeks(new Date(startDateRetail), weeksToAdd - 52);
          let et = addWeeks(new Date(endOfStartDateRetail), weeksToAdd - 52);
          this.setComparedPeriod(st, et);
        }
      } else if (comparedOption === 'previous_year_same_month') {
        let retailDetails =
          this.retailCalendar.find(
            v => v.label.toString() === (this.retailYear.value - 1).toString()
          ) || null;
        if (retailDetails) {
          const prevYearMonths = this.getRetailMonths(
            retailDetails.startDate,
            12,
            retailDetails.numberOfWeeks
          );

          const currMonthIndex = this.retailMonths.findIndex(
            v => v === this.retailMonth
          );

          let st = new Date(
            prevYearMonths[currMonthIndex]?.value?.startingPeriod
          );
          let et = new Date(
            prevYearMonths[currMonthIndex]?.value?.endingPeriod
          );
          this.setComparedPeriod(st, et);
        } else {
          this.setComparedPeriod(
            addYears(this.selectedPeriod.start, -1),
            addYears(this.selectedPeriod.end, -1)
          );
        }
      } else if (comparedOption === 'tutuanna_calender') {
        this.specialCalenderMode = true;
        this.calenderMode = 'multiple';
        this.getComparedDatesArray();
      }
      this.setMonthPoints();
    },

    dateOptionsComparedModelChangedNew(res) {
      let comparedOption;
      if (!res) {
        comparedOption = this.dateOptionsComparedModel.value;
      } else if (res && Object.keys(res).length == 2) {
        comparedOption = res.value;
      } else {
        comparedOption = res;
      }
      this.specialCalenderMode = false;
      this.calenderMode = 'range';
      if (comparedOption === 'PREVIOUS_PERIOD') {
        const diffDays = differenceInDays(
          this.selectedPeriod.start,
          this.selectedPeriod.end
        );
        const diffDaysPositive =
          diffDays < 0 ? (diffDays - 1) * -1 : diffDays + 1;
        const weeks = Math.ceil((diffDaysPositive / 7).toFixed(2));
        this.setComparedPeriod(
          addWeeks(this.selectedPeriod.start, -weeks),
          addWeeks(this.selectedPeriod.end, -weeks)
        );
      } else if (comparedOption === 'PREVIOUS_YEAR_SAME_PERIOD') {
        const previousYearStartDate = subYears(
          new Date(this.selectedPeriod.start),
          1
        );
        const previousYearEndDate = subYears(
          new Date(this.selectedPeriod.end),
          1
        );
        this.setComparedPeriod(previousYearStartDate, previousYearEndDate);
        // const diffDays = differenceInDays(
        //   this.selectedPeriod.start,
        //   this.selectedPeriod.end
        // );
        // this.setComparedPeriod(addDays(new Date(this.selectedPeriod.start), -365), addDays(new Date(this.selectedPeriod.end), -365))
      } else if (comparedOption === 'PREVIOUS_YEAR_SAME_DAY_OF_WEEK') {
        this.setComparedPeriod(
          addDays(new Date(this.selectedPeriod.start), -364),
          addDays(new Date(this.selectedPeriod.end), -364)
        );
      } else if (comparedOption === 'previous_year_same_week') {
        const prevYear =
          this.retailCalendar.find(
            v =>
              v.label.toString() ===
              (
                this.retailYear?.value ??
                new Date(this.selectedPeriod.start).getFullYear() - 1
              ).toString()
          ) || null;
        const weeksToAdd = this.retailWeeks.findIndex(
          v => v === this.retailWeek
        );
        if (prevYear) {
          const startDateRetail = prevYear.startDate;
          const endOfStartDateRetail = moment(
            addDays(new Date(startDateRetail), 6)
          ).format('YYYY-MM-DD');
          this.setComparedPeriod(
            addWeeks(new Date(startDateRetail), weeksToAdd),
            addWeeks(new Date(endOfStartDateRetail), weeksToAdd)
          );
        } else {
          const currYear = this.retailCalendar.find(
            v => v.label.toString() === this.retailYear.value.toString()
          );
          const startDateRetail = currYear.startDate;
          const endOfStartDateRetail = moment(
            addDays(new Date(startDateRetail), 6)
          ).format('YYYY-MM-DD');
          let st = addWeeks(new Date(startDateRetail), weeksToAdd - 52);
          let et = addWeeks(new Date(endOfStartDateRetail), weeksToAdd - 52);
          this.setComparedPeriod(st, et);
        }
      } else if (comparedOption === 'previous_year_same_month') {
        let retailDetails =
          this.retailCalendar.find(
            v => v.label.toString() === (this.retailYear.value - 1).toString()
          ) || null;
        if (retailDetails) {
          const prevYearMonths = this.getRetailMonths(
            retailDetails.startDate,
            12,
            retailDetails.numberOfWeeks
          );

          const currMonthIndex = this.retailMonths.findIndex(
            v => v === this.retailMonth
          );

          let st = new Date(
            prevYearMonths[currMonthIndex]?.value?.startingPeriod
          );
          let et = new Date(
            prevYearMonths[currMonthIndex]?.value?.endingPeriod
          );
          this.setComparedPeriod(st, et);
        } else {
          this.setComparedPeriod(
            addYears(this.selectedPeriod.start, -1),
            addYears(this.selectedPeriod.end, -1)
          );
        }
      } else if (comparedOption === 'tutuanna_calender') {
        this.specialCalenderMode = true;
        this.calenderMode = 'multiple';
        this.getComparedDatesArray();
      }
      localStorage.setItem('PREVIOUS_YEAR_SAME_PERIOD', comparedOption);
      this.setMonthPoints();
      this.comparedPeriodCheck = 1;
    },
    getComparedDatesArray() {
      this.comparedPeriod = {};
      this.comparedPeriod = this.selectedDateRange.map(
        dat => new Date(this.getComparedDate(dat))
      );
    },
    marketingCampaignOptionChange() {
      let val = this.marketingCampaignOption.value;
      this.setSelectedPeriod(new Date(val.startDate), new Date(val.endDate));
    },
    setMonthPoints() {
      if (this.dateOptionsSelectedModel.value === 'retail_calendar') {
        {
          let selectYear = this.retailYear
            ? this.retailYear.value
            : moment(new Date(this.selectedPeriod.start)).format('YYYY');
          const retailCal = this.retailCalendar.find(
            v => v.label.toString() === selectYear.toString()
          );

          const compRetailCal = this.retailCalendar.find(
            v => v.label.toString() === (parseInt(selectYear) - 1).toString()
          );
          let { numberOfWeeks, startDate } = retailCal ? retailCal : {};
          const currentMonthList = this.getRetailMonths(
            startDate,
            12,
            numberOfWeeks
          );
          let { compNumberOfWeeks, compStartDate } = compRetailCal
            ? {
                compNumberOfWeeks: compRetailCal.numberOfWeeks || 52,
                compStartDate: compRetailCal.startDate
              }
            : {};
          const nonRetailMonth = {
            startingPeriod: moment(
              startOfMonth(new Date(this.filter?.startingPeriod))
            ).format('YYYY-MM-DD'),
            endingPeriod: moment(
              endOfMonth(new Date(this.filter?.startingPeriod))
            ).format('YYYY-MM-DD')
          };

          let monthIndex = currentMonthList.findIndex(v => {
            return (
              this.selectedPeriodDate >= new Date(v.value?.startingPeriod) &&
              addDays(this.selectedPeriodDate, 6) <=
                new Date(v.value.endingPeriod)
            );
          });
          monthIndex =
            monthIndex === -1
              ? currentMonthList.findIndex(v => {
                  return (
                    this.selectedPeriod.start >=
                      new Date(v.value?.startingPeriod) &&
                    addDays(this.selectedPeriod.start, 3) <=
                      new Date(v.value.endingPeriod)
                  );
                })
              : monthIndex;
          let month = this.retailMonths[monthIndex]?.value ?? nonRetailMonth;
          let monthCompared = {};

          const prevYearMonthList = this.getRetailMonths(
            compStartDate,
            12,
            compNumberOfWeeks
          );

          if (
            [
              'PREVIOUS_YEAR_SAME_PERIOD',
              'PREVIOUS_PERIOD',
              'PREVIOUS_YEAR_SAME_WEEK'
            ].includes(this.dateOptionsComparedModel.value)
          ) {
            monthCompared = prevYearMonthList[monthIndex]?.value;
            // monthCompared = {
            //   startingPeriod: moment(
            //     addDays(addYears(new Date(month.startingPeriod), -1), -1)
            //   ).format('YYYY-MM-DD'),
            //   endingPeriod: moment(
            //     addYears(new Date(month.endingPeriod), -1)
            //   ).format('YYYY-MM-DD')
            // };
          } else {
            monthCompared = {
              startingPeriod: moment(
                addYears(new Date(month?.startingPeriod), -1)
              ).format('YYYY-MM-DD'),
              endingPeriod: moment(
                addYears(new Date(month?.endingPeriod), -1)
              ).format('YYYY-MM-DD')
            };
          }
          let year = {};
          if (numberOfWeeks && startDate) {
            year = {
              startingPeriod: startDate,
              endingPeriod: moment(
                addDays(addWeeks(new Date(startDate), numberOfWeeks), -1)
              ).format('YYYY-MM-DD')
            };
          } else {
            year = {
              startingPeriod: moment(
                startOfYear(new Date(this.filter?.startingPeriod))
              ).format('YYYY-MM-DD'),
              endingPeriod: moment(
                addDays(endOfYear(new Date(this.filter.startingPeriod)), -1)
              ).format('YYYY-MM-DD')
            };
          }

          let yearCompared = {};

          if (compNumberOfWeeks && compStartDate) {
            yearCompared = {
              startingPeriod: compStartDate,
              endingPeriod: moment(
                addDays(
                  addWeeks(new Date(compStartDate), compNumberOfWeeks),
                  -1
                )
              ).format('YYYY-MM-DD')
            };
          } else {
            yearCompared = {
              startingPeriod: moment(
                addYears(startOfYear(new Date(this.filter.startingPeriod)), -1)
              ).format('YYYY-MM-DD'),
              endingPeriod: moment(
                addYears(endOfYear(new Date(this.filter?.startingPeriod)), -1)
              ).format('YYYY-MM-DD')
            };
          }
          this.setMonthlyPoints({ current: month, compared: monthCompared });
          this.setYearlyPoints({ current: year, compared: yearCompared });
        }
      }
    },
    refreshPage() {
      this.$emit('refreshPage');
    },
    submitDate() {
      localStorage.removeItem('weather');
      localStorage.removeItem('weatherCompare');
      //
      localStorage.removeItem('weatherHourly');
      localStorage.removeItem('weatherHourlyC');
      this.fetchWeather(this.citiesList);
      this.setCalendarDatesToStore({
        selectedOption: this.dateOptionsSelectedModel.value,
        comparedOption: this.dateOptionsComparedModel.value,
        selectedPeriod: this.selectedPeriod,
        comparedPeriod: this.comparedPeriod
      });

      if (!this.tutuannaMode) {
        this.setStartingCompared({
          startingCompared: format(this.comparedPeriod.start, 'yyyy-MM-dd')
        });
        this.setEndingCompared({
          endingCompared: format(this.comparedPeriod.end, 'yyyy-MM-dd')
        });
      }
      this.setCustomCalenderFlag({ value: this.tutuannaMode });

      this.hide();
      this.hide2();
      this.refreshPage();
    },
    hide() {
      this.$refs.periodFilter.hide();
    },
    hide2() {
      this.$refs.periodFilter2.hide();
    },
    formatDate(date) {
      if (!date) return null;
      let newDate = date.toISOString().substr(0, 10);
      return newDate;
    },
    getComparedDate(date) {
      if (date && this.compareDates) {
        let dateObj = this.compareDates.filter(dat => dat.date === date);
        if (dateObj.length > 0) {
          return new Date(dateObj[0].comparedDate);
        } else {
          return '';
        }
      }
    }, // hide
    handleScroll(evt, el) {
      this.scrolled = window.scrollY > 10;
    },
    getFullDay(val) {
      let dayVal = new Date(val).getDay();
      if (dayVal === 0) {
        return this.$t('FilterBar.Sun');
      }
      if (dayVal === 1) {
        return this.$t('FilterBar.Mon');
      }
      if (dayVal === 2) {
        return this.$t('FilterBar.Tue');
      }
      if (dayVal === 3) {
        return this.$t('FilterBar.Wed');
      }
      if (dayVal === 4) {
        return this.$t('FilterBar.Thu');
      }
      if (dayVal === 5) {
        return this.$t('FilterBar.Fri');
      }
      if (dayVal === 6) {
        return this.$t('FilterBar.Sat');
      }
    },
    getDifferenceDays(a, b) {
      let val = differenceInDays(new Date(a), new Date(b));
      return val < 0 ? -val : val;
    },
    getFormattedDate(date) {
      return moment(new Date(date)).format('YYYY/MM/DD');
    },
    dropDownToggled(val) {
      this.init();
      if (val == 1) {
        this.hide2();
      } else if (val == 2) {
        this.hide();
      }
    }
  }
};
