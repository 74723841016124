
import moment from 'moment';
import stores from '@/services/stores';
import HoursModal from './hoursModal.vue';
import SpecialHoursModal from './SpecialHoursModal.vue';

export default {
  components: {
    HoursModal,
    SpecialHoursModal
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['selectedStore', 'storeList'],
  data() {
    return {
      isModalVisible: false,
      menuVisible: {}, // Reactive state for q-menu visibility
      isEditModalOpen: false,
      modalMode: 'edit' // 'edit' or 'confirm'
    };
  },
  computed: {
    regularHours() {
      const { storeTimings } = this.selectedStore || {};
      const days = [
        'sunday',
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday'
      ];
      const timeReg = storeTimings?.filter(item => !item.dates) || [];

      return days.map(day => {
        const dayTiming = timeReg.find(item => item.day === day);
        return dayTiming || { day };
      });
    },

    specialHours() {
      return this.selectedStore.storeTimings?.filter(item =>
        item.hasOwnProperty('dates')
      );
    }
  },
  methods: {
    addHour(time){
      const minutes = moment(time, "HH:mm").minutes();
      if(minutes > 0){
        return moment(time, 'HH:mm').format('HH:mm')
      }else{
        return moment(time, 'HH:mm').add(1,'hour').format('HH:mm')
      }
    },
    formatDay(date) {
      return moment(date).format('ddd');
    },
    toggleMenu(index) {
      // Close all menus except the current one
      Object.keys(this.menuVisible).forEach(key => {
        this.menuVisible[key] = false;
      });
      this.$set(this.menuVisible, index, true);
    },
    closeModal() {
      this.isModalVisible = false;
    },
    openRegularModal() {
      this.modalMode = 'edit';
      this.isEditModalOpen = true;
    },
    openSpecialHoursModal() {
      this.isModalVisible = true;
      setTimeout(() => {
        if (this.$refs.specialHour) {
          const editAdd = this.$refs.specialHour;
          editAdd.isEdit = false;
        }
      }, 500);
    },
    closeRegularModal() {
      this.modalMode = false;
      this.isEditModalOpen = false;
    },
    goToConfirmation() {
      this.modalMode = 'confirm';
    },
    saveChanges() {
      this.isEditModalOpen = false;
    },
    editItem(index) {
      this.isModalVisible = true;
      setTimeout(() => {
        if (this.$refs.specialHour) {
          const editAdd = this.$refs.specialHour;
          const data = this.specialHours[index];
          editAdd.selectedDate = data['dates'][0];
          editAdd.shortDescription = data.day;
          editAdd.openTime = data.openingTime?.slice(0, 5);
          editAdd.closeTime = data.closingTime?.slice(0, 5);
          editAdd.isEdit = true;
          editAdd.isEditIndex = index;
        }
      }, 500);
    },
    deleteItem(index) {
      let special = this.selectedStore.storeTimings?.filter(item =>
        item.hasOwnProperty('dates')
      );
      const regular = this.selectedStore.storeTimings?.filter(
        item => !item.hasOwnProperty('dates')
      );
      // index edit
      special.splice(index, 1);
      //
      let dataT = [...regular, ...special]?.map(item => {
        return {
          ...item,
          openingTime: item.openingTime?.slice(0, 5),
          closingTime: item.closingTime?.slice(0, 5)
        };
      });
      //

      const payload = {
        storeId: this.selectedStore && this.selectedStore._id,
        storeTimings: [...dataT]
      };
      stores
        .updateStore(payload, true)
        .then(() => {
          this.$emit('refetchData');
          this.$q.notify({
            message: this.$store.state.user.translate.updated_successfully,
            color: 'green'
          });
        })
        .catch(error => {
          console.log('error:', error);
        });
    }
  }
};
