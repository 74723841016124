
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import {exportToPDF} from '@/util/reports/exportopdf.js'
import {downloadPDF} from '@/util/utilFidaFunctions'
import store from '@/store';
import { APICreatePreferencesPulse } from '@/api/kpi';
import {userCheck} from '@/api/fidaApi';

export default {
  data() {
    return {
      isSavingReport: false,
      showPreviewModal: false,
      formFields: {
        numberOfEmployees: '',
        attendanceStaff: '',
        reportTitle: '',
        marketReport: '',
        nextActionPlan: '',
        lastWeekActionPlan: '',
        reviewActionPlan: '',
        createdOn: ''
      },
      confirmSaveReport: false,
      currentUser: 'SuperUser',
      selectedPeriod: null,
      loading: false,
      reportTypeData: {
        daily_report: 'daily',
        weekly_report: 'Weekly',
        monthly_report: 'Monthly'
      }
    };
  },
  computed: {
    ...mapGetters('filter', [
      'getStoreCodesList',
      'getDaysOfWeekList',
      'getStartingPeriod',
      'getEndingPeriod',
      'getStartingCompared',
      'getEndingCompared',
      'getCustomCalenderState',
      'getEndingCompared',
      'getPulseStartingPeriod',
      'getPulseEndingPeriod',
      'getPulseStartingCompared',
      'getPulseEndingCompared',

    ]),
    ...mapState('periodicReport', [
      'currentReport',
      'filterFormState',
      'selectedStoreCode',
      'lastPeriodValues',
    ]),
    ...mapActions("store", ["fetchStores"]),
    ...mapGetters('periodicReport', ['getMonthlyLoading']),
    ...mapState('user', ['profile']),
    ...mapState('distribution', ['selectedKPIOptions']),
    monthlyLoading() {
      return this.getMonthlyLoading;
    }
  },
  watch: {
    lastPeriodValues() {
      this.formFields.lastWeekActionPlan = this.lastPeriodValues.nextActionPlan;
    },
    currentReport(newValue) {
      this.formFields = { ...newValue };
    },
    async getStartingPeriod() {
      await this.loadLastPeriodValues();
    },
    async getEndingPeriod() {
      await this.loadLastPeriodValues();
    },
    'formFields.numberOfEmployees': function(newValue) {
      this.setFilterForm({
        filterFormKey: 'numberOfEmployees',
        filterFormValue: newValue
      });
    },
    'formFields.attendanceStaff': function(newValue) {
      this.setFilterForm({
        filterFormKey: 'attendanceStaff',
        filterFormValue: newValue
      });
    },
    'formFields.reportTitle': function(newValue) {
      this.setFilterForm({
        filterFormKey: 'reportTitle',
        filterFormValue: newValue
      });
    },
    'formFields.marketReport': function(newValue) {
      this.setFilterForm({
        filterFormKey: 'marketReport',
        filterFormValue: newValue
      });
    },
    'formFields.nextActionPlan': function(newValue) {
      this.setFilterForm({
        filterFormKey: 'nextActionPlan',
        filterFormValue: newValue
      });
    },
    'formFields.lastWeekActionPlan': function(newValue) {
      this.setFilterForm({
        filterFormKey: 'lastWeekActionPlan',
        filterFormValue: newValue
      });
    },
    'formFields.reviewActionPlan': function(newValue) {
      this.setFilterForm({
        filterFormKey: 'reviewActionPlan',
        filterFormValue: newValue
      });
    }
  },
  async created() {
    await this.fetchStores();
  //  this.$refs.monthlyForm.resetValidation();
    await this.loadLastPeriodValues();
  },
  methods: {
    ...mapActions('periodicReport', [
      'updatePeriodicReport',
      'getFormLastPeriodValues',
      "createPeriodicReport"
    ]),
    ...mapMutations('periodicReport', ['setFilterForm']),
    resetForm() {
      this.formFields = {
        numberOfEmployees: null,
        attendanceStaff: null,
        reportTitle: null,
        marketReport: null,
        nextActionPlan: null,
        lastWeekActionPlan: null,
        reviewActionPlan: null,
        createdOn: null
      };
    },
    toggleModal(value) {
      this.showPreviewModal = value;
    },
    removeForm() {
      const element = document.getElementsByClassName('export-form');
      [...element].map(n => n && n.remove());
    },
    createForm() {
      return `
        <div class="export-form">
          <div class="report-view-wrapper">
            <div class="report-view monthly-report">
              <div class="report-title"> ${this.$t('monthlyReport')}</div>
              <div class="report-content">
                ${this.formFields.reportTitle}
              </div>
            </div>
            <div class="report-view">
              <div class="report-title">${this.$t('MarketReport')}</div>
              <div class="report-content">
                ${this.formFields.marketReport}
              </div>
            </div>
          </div>
           <div class="report-view next-plan">
            <div class="report-title">Last Week Action Plan</div>
            <div class="report-content">
              ${this.formFields.lastWeekActionPlan}
            </div>
          </div>
           <div class="report-view next-plan">
            <div class="report-title">Review Action Plan</div>
            <div class="report-content">
              ${this.formFields.reviewActionPlan}
            </div>
          </div>
          <div class="report-view next-plan">
            <div class="report-title">Next Action Plan</div>
            <div class="report-content">
              ${this.formFields.nextActionPlan}
            </div>
          </div>
        </div>
      `;
    },
    createFormNode() {
      const node = document.createElement('div');
      node.innerHTML = this.createForm();
      return node;
    },
    createFormRequestBody() {
        let formData = new FormData();
      const requestBody = {
        compareEndDate: this.getPulseEndingCompared,
        compareStartDate: this.getPulseStartingCompared,
        endDate: this.getPulseEndingPeriod,
        kpis: this.selectedKPIOptions.join(','),
        startDate: this.getPulseStartingPeriod,
        storeCodes: this.selectedStoreCode,
        reportTitle: this.formFields.reportTitle,
        marketReport: this.formFields.marketReport,

        nextActionPlan: this.formFields.nextActionPlan,
        lastWeekActionPlan: this.formFields.lastWeekActionPlan,
        reviewActionPlan: this.formFields.reviewActionPlan,
        fileName: '',
        reportType: "MONTHLY",

      };
    return requestBody;
    },

    async submitReport() {
      try {
        this.loading = true;
        this.isSavingReport = true;
      //  const pdfFile = await exportToPDF(".report-preview", this.$q.notify);
        const formData = new FormData();

        const objectDatac = this.createFormRequestBody();

        if (this.$route.params.reportId) {
            objectDatac._id = this.$route.params.reportId;
            const res = await this.updatePeriodicReport({
            body: objectDatac,
          });
    
        } else {
          const res = await this.createPeriodicReport({
            body: objectDatac,
          });
        }

        this.showPreviewModal = false;

        this.$q.notify({
          type: 'positive',
          position: 'top',
          message: this.$t('SettingsManagement.report_saved_successfully')
        });
          this.$router.push("/periodic-report-list");
      } catch (error) {
        this.$q.notify({
          type: 'negative',
          position: 'top',
          message: `Error: ${error}`
        });
      } finally {
        // this.removeForm();
        this.loading = false;
        this.isSavingReport = false;
      }
    },
    async loadLastPeriodValues() {
      await this.getFormLastPeriodValues({
        reportType: 'monthly',
        storeCodes: this.selectedStoreCode,
        startDate: this.getStartingPeriod,
        endDate: this.getEndingPeriod
      });
    }
  }
};
