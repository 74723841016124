
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import {exportToPDF} from '@/util/reports/exportopdf.js'
import {downloadPDF} from '@/util/utilFidaFunctions'
import store from '@/store';
const { v4: uuidv4 } = require('uuid');

import { APICreatePreferencesPulse } from '@/api/kpi';
  import {userCheck} from '@/api/fidaApi';
export default {
  data() {
    return {
      weeklyForm: null,
      isReportSaving: false,
      showPreviewModal: false,
      formFields: {
        numberOfEmployees: null,
        attendanceStaff: null,
        reportTitle: null,
        marketReport: null,
        nextActionPlan: null,
        lastWeekActionPlan: null,
        reviewActionPlan: null
      },
      confirmSaveReport: false,
      currentUser: "SuperUser",
      selectedPeriod: null,
      loading: false,
      reportTypeData: {
        daily_report: "daily",
        weekly_report: "Weekly",
        monthly_report: "Monthly",
      },
    };
  },
  computed: {
    ...mapGetters("filter", [
      "getPulseStartingPeriod",
      "getPulseEndingPeriod",
      "getPulseStartingCompared",
      "getPulseEndingCompared"
    ]),
    ...mapState("periodicReport", [
      "currentReport",
      "selectedStoreCode",
      "lastPeriodValues",
    ]),
    
    ...mapGetters("periodicReport", ["getWeeklyLoading"]),
    ...mapState("user", ["profile"]),
    ...mapState('store', ['stores']),
    ...mapState("distribution", ["selectedKPIOptions"]),
    weeklyLoading() {
      return this.getWeeklyLoading;
    },
  },
  watch: {
    lastPeriodValues() {
      this.formFields.lastWeekActionPlan = this.lastPeriodValues.nextActionPlan?this.lastPeriodValues.nextActionPlan:'';
    },
    currentReport(newValue) {
      this.formFields = { ...newValue };
    },
   /* async getPulseStartingPeriod() {
      await this.loadLastPeriodValues();
    },
    async getPulseEndingPeriod() {
      await this.loadLastPeriodValues();
    },
    async selectedStoreCode() {
      await this.loadLastPeriodValues();
    },*/
  },
  async created() {
    await this.loadLastPeriodValues();
    await this.fetchStores();
  },
  methods: {
    ...mapActions("periodicReport", [
      "updatePeriodicReport",
      "getFormLastPeriodValues",
      "createPeriodicReport"
    ]),
    ...mapActions("store", ["fetchStores"]),
    ...mapMutations("periodicReport", ["setFilterForm"]),
    resetForm() {
      this.formFields = {
        numberOfEmployees: null,
        attendanceStaff: null,
        reportTitle: null,
        marketReport: null,
        nextActionPlan: null,
        lastWeekActionPlan: null,
        reviewActionPlan: null,
        createdOn: null
      };
    },

    toggleModal(value) {
      this.showPreviewModal = value;
    },
    removeForm() {
      const element = document.getElementsByClassName("export-form");
      [...element].map((n) => n && n.remove());
    },
    createForm() {
      return `
        <div class="export-form">
          <div class="report-view-wrapper">
            <div class="report-view monthly-report next-plan">
              <div class="report-title">Monthly Report</div>
              <div class="report-content">
                ${this.formFields.reportTitle}
              </div>
            </div>
            <div class="report-view next-plan">
              <div class="report-title">Market Report</div>
              <div class="report-content">
                ${this.formFields.marketReport}
              </div>
            </div>
          </div>
           <div class="report-view next-plan">
            <div class="report-title">Last Week Action Plan</div>
            <div class="report-content">
              ${this.formFields.lastWeekActionPlan}
            </div>
          </div>
           <div class="report-view next-plan">
            <div class="report-title">Review Action Plan</div>
            <div class="report-content">
              ${this.formFields.reviewActionPlan}
            </div>
          </div>
          <div class="report-view next-plan">
            <div class="report-title">Next Action Plan</div>
            <div class="report-content">
              ${this.formFields.nextActionPlan}
            </div>
          </div>
        </div>
      `;
    },
    createFormNode() {
      const node = document.createElement("div");
      node.innerHTML = this.createForm();
      return node;
    },
    async submitForm() {
      try {
        
      this.setFilterForm({
        filterFormKey: "numberOfEmployees",
        filterFormValue: this.formFields.numberOfEmployees,
      });
      this.setFilterForm({
        filterFormKey: "attendanceStaff",
        filterFormValue: this.formFields.attendanceStaff,
      });
      this.setFilterForm({
        filterFormKey: "reportTitle",
        filterFormValue: this.formFields.reportTitle,
      });
      this.setFilterForm({
        filterFormKey: "marketReport",
        filterFormValue: this.formFields.marketReport,
      });
      this.setFilterForm({
        filterFormKey: "nextActionPlan",
        filterFormValue: this.formFields.nextActionPlan,
      });
      this.setFilterForm({
        filterFormKey: "lastWeekActionPlan",
        filterFormValue: this.formFields.lastWeekActionPlan,
      });
      this.setFilterForm({
        filterFormKey: "reviewActionPlan",
        filterFormValue: this.formFields.reviewActionPlan,
      });
        this.showPreviewModal = true;
      }
      catch(error)
      {
        console.log(error);
      }
    },
    createFormRequestBody() {
        let formData = new FormData();
      const requestBody = {
        compareEndDate: this.getPulseEndingCompared,
        compareStartDate: this.getPulseStartingCompared,
        endDate: this.getPulseEndingPeriod,
        kpis: this.selectedKPIOptions.join(','),
        startDate: this.getPulseStartingPeriod,
        storeCodes: this.selectedStoreCode,
        reportTitle: this.formFields.reportTitle,
        marketReport: this.formFields.marketReport,

        nextActionPlan: this.formFields.nextActionPlan,
        lastWeekActionPlan: this.formFields.lastWeekActionPlan,
        reviewActionPlan: this.formFields.reviewActionPlan,
        fileName: '',
        reportType: "WEEKLY",

      };

      return requestBody;
    },
    async submitReport() {
      try {
        this.loading = true;
        this.isReportSaving = true;
      
        const formData = new FormData();
        const objectDatac = this.createFormRequestBody();

        if (this.$route.params.reportId) {
            objectDatac._id = this.$route.params.reportId;

            const res = await this.updatePeriodicReport({
            body: objectDatac,
          });
    
        } else {
          const res = await this.createPeriodicReport({
            body: objectDatac,
          });
        }

    this.showPreviewModal = false;

     
      /*  {
          const res = await this.submitPeriodicReport({
            reportType: this.reportTypeData[this.$route.name],
            body: formData,
          });
          if (res.status === "ERROR") {
            throw new Error(res.description);
          }
        }*/
        this.$q.notify({
          type: "positive",
          position: "top",
          message: this.$t("SettingsManagement.report_saved_successfully"),
        });
        this.$router.push("/periodic-report-list");
      } catch (error) {
        this.$q.notify({
          type: "negative",
          position: "top",
          message: `Error: ${error}`,
        });
      } finally {
        // this.removeForm();
        this.isReportSaving = false;
        this.loading = false;
      }
    },
    async loadLastPeriodValues() {
      await this.getFormLastPeriodValues({
        reportType: "WEEKLY",
        storeCodes: this.selectedStoreCode,
        startDate: this.getPulseStartingPeriod,
        endDate: this.getPulseEndingPeriod,
      });
    },
  },
};
