import { render, staticRenderFns } from "./SpecialHoursModal.vue?vue&type=template&id=12ffc202&scoped=true&"
import script from "./SpecialHoursModal.vue?vue&type=script&lang=js&"
export * from "./SpecialHoursModal.vue?vue&type=script&lang=js&"
import style0 from "./SpecialHoursModal.vue?vue&type=style&index=0&id=12ffc202&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12ffc202",
  null
  
)

export default component.exports
import {QDialog,QCard,QCardSection,QItemLabel,QInput,QIcon,QPopupProxy,QDate,QBtn,QSelect,QCardActions,ClosePopup} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QDialog,QCard,QCardSection,QItemLabel,QInput,QIcon,QPopupProxy,QDate,QBtn,QSelect,QCardActions})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {ClosePopup})
