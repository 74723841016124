
  import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
  import {
    format,
    startOfWeek,
    endOfWeek,
    startOfYesterday,
    startOfMonth,
    endOfMonth,
    startOfYear,
    endOfYear,
    addYears,
    addDays,
    addWeeks,
    addMonths,
    differenceInDays,
    subYears
  } from 'date-fns';
  import moment from 'moment';
  import {getDayDiffFromMonday, getStartDayOfWeek} from '@/util/calendar.js'
  
  export default {
    props: {
      selecttype: {
        required: false,
        type: String,
        default: 'single'
      },
      calendartype: {
        required: false,
        type: String,
        default: 'daily'
      }
      // setdatefilter: {
      //   required: true,
      //   type: Function,
      //   default: () => {}
      // }Period
    },
    data() {
      return {
        retailMonth: null,
        retailMonths: [],
        retailWeek: '',
        selectMode: 'clicked',
        filterRetailWeeks: [],
        dailyOptions: { label: this.$t('CustomSelect'), value: 'custom' },
        dailyPeriodOptions: [
          { label: this.$t('CustomSelect'), value: 'custom' },
          { label: this.$t('Today'), value: 'TODAY' },
          { label: this.$t('Yesterday'), value: 'YESTERDAY' }
        ],
        retailYear: null,
        monthList: [
          { label: this.$t('FilterBar.January'), value: 'Jan' },
          { label: this.$t('FilterBar.February'), value: 'Feb' },
          { label: this.$t('FilterBar.March'), value: 'Mar' },
          { label: this.$t('FilterBar.April'), value: 'Apr' },
          { label: this.$t('FilterBar.May'), value: 'May' },
          { label: this.$t('FilterBar.June'), value: 'Jun' },
          { label: this.$t('FilterBar.July'), value: 'Jul' },
          { label: this.$t('FilterBar.August'), value: 'Aug' },
          { label: this.$t('FilterBar.September'), value: 'Sep' },
          { label: this.$t('FilterBar.October'), value: 'Oct' },
          { label: this.$t('FilterBar.November'), value: 'Nov' },
          { label: this.$t('FilterBar.December'), value: 'Dec' }
        ],
        retailMonthsOptions: [
          { label: this.$t('FilterBar.January'), value: 'Jan' },
          { label: this.$t('FilterBar.February'), value: 'Feb' },
          { label: this.$t('FilterBar.March'), value: 'Mar' },
          { label: this.$t('FilterBar.April'), value: 'Apr' },
          { label: this.$t('FilterBar.May'), value: 'May' },
          { label: this.$t('FilterBar.June'), value: 'Jun' },
          { label: this.$t('FilterBar.July'), value: 'Jul' },
          { label: this.$t('FilterBar.August'), value: 'Aug' },
          { label: this.$t('FilterBar.September'), value: 'Sep' },
          { label: this.$t('FilterBar.October'), value: 'Oct' },
          { label: this.$t('FilterBar.November'), value: 'Nov' },
          { label: this.$t('FilterBar.December'), value: 'Dec' }
        ],
        retailMonthSelection: '',
        retailWeeks: [],
        retailYears: [],
        attrs: [
          {
            key: 'TODAY',
            highlight: true,
            dates: new Date()
          }
        ],
        attrsCompared: [
          {
            key: "Holidays",
            highlight: true,
            popover: {
              label: "",
            },
            dates: [],
          }
        ],
        calenderMode: 'range',
        dragValue: null,
        selectedPeriodDate:new Date(),
        selectedPeriodState: false,
        selectedDateRange: [],
        dateOptionsSelectedModel: {
          label: this.$t('custom_range'),
          value: 'custom_range'
        },
        dateOptionsComparedModel: {
          label: this.$t('previous_period'),
          value: 'PREVIOUS_PERIOD'
        },
        marketingCampaignMode: false,
        marketingCampaignOption: null,
        marketingCampaignOptionList: [],
        dateOptionsSelected: [
          {
            label: this.$t('daily'),
            value: 'TODAY'
          },
          {
            label: this.$t('yesterday'),
            value: 'YESTERDAY'
          },
          {
            label: this.$t('this_week'),
            value: 'THIS_WEEK'
          },
          {
            label: this.$t('this_month'),
            value: 'THIS_MONTH'
          },
          {
            label: this.$t('this_year'),
            value: 'THIS_YEAR'
          },
          {
            label: this.$t('last_year'),
            value: 'LAST_YEAR'
          },
          {
            label: this.$t('custom_range'),
            value: 'custom_range'
          }
        ],
        dateOptionsCompared: [
          {
            label: this.$t('previous_period'),
            value: 'PREVIOUS_PERIOD'
          },
          {
            label: this.$t('previous_year_same_period'),
            value: 'PREVIOUS_YEAR_SAME_PERIOD'
          },
          {
            label: this.$t('FilterBar.previous_year_same_day_of_week'),
            value: 'PREVIOUS_YEAR_SAME_DAY_OF_WEEK'
          },
  
        ],
        selectedPeriod: {
          start: new Date(), // Jan 16th, 2018
          end: new Date() // Jan 19th, 2018
        },
        comparedPeriod: {
          start: addDays(new Date(), -1), // Jan 16th, 2018
          end: addDays(new Date(), -1) // Jan 19th, 2018
        },
        lastTimeStamp: Date.now(),
        startDayFromMonday: 0,
        startDayOfWeek: 1,
      };
    }, // data
    computed: {
      ...mapGetters('user', ['getLocale']),
      ...mapState('user', ['profile', 'startOfWeek', "defaultSettings"]),
      ...mapState("filter", ["filter", "holidays", "retailCalendar"]),
      ...mapState('store', ['stores']),
      ...mapState('periodicReport', ['selectedStoreCode', 'currentReport', 'selectedStore']),
      ...mapState('comparison', ['marketingCampaign', 'compareDates']),
      ...mapGetters('filter', [
        'getStoreCodesList',
        'getPulseStartingPeriod',
        'getPulseEndingPeriod',
        'getPulseStartingCompared',
        'getPulseEndingCompared'
      ]),
      selectDragAttribute() {
        return {
          popover: {
            visibility: this.tutuannaMode ? 'hover' : 'none',
            isInteractive: false // Defaults to true when using slot
          }
        };
      },
      numberofRetailWeeks() {
        return this.retailCalendar.find(
          v => v.label.toString() === this.retailYear?.value.toString()
        )?.numberOfWeeks;
      },
      startDateOfRetailYear() {
        return this.retailCalendar.find(
          v => v.label.toString() === this.retailYear?.value.toString()
        )?.startDate;
      },
      dragComparedValue: function() {
        let value = '';
        if (this.dragValue && this.compareDates) {
          value = this.getComparedDate(this.formatDate(this.dragValue));
          return this.formatDate(value);
        } else {
          return value;
        }
      },
      
    }, // computed
    watch: {
      stores(newData){
        this.updateCloseCalender(newData, [this.selectedStore.value])
      },
      holidays() {
        this.setAttrs()
      },
      dailyOptions() {
        if (this.dailyOptions.value === 'TODAY') {
          this.selectedPeriodDate = new Date();
        } else if (this.dailyOptions.value === 'YESTERDAY') {
          this.selectedPeriodDate = addDays(new Date(), -1);
        }
        this.setSelectedPeriod();
      },
      retailYear() {
        let num = this.retailCalendar.find(
          v => v.label.toString() === this.retailYear.value.toString()
        )?.numberOfWeeks;
        let startDateRetail = this.retailCalendar.find(
          v => v.label.toString() === this.retailYear.value.toString()
        )?.startDate;
        this.retailMonthSelection = {
          label: this.$t(`FilterBar.${moment(startDateRetail).format('MMMM')}`),
          value: moment(startDateRetail).format('MMM')
        };
  
        let monthIndex = this.monthList.findIndex(
          v => v.value === this.retailMonthSelection.value
        );
        this.retailMonthsOptions = [
          ...this.monthList.slice(monthIndex, 12),
          ...this.monthList.slice(0, monthIndex)
        ];
        this.getRetailWeeks(startDateRetail, 12, num);
      },
      retailWeek() {
        if (this.retailWeek) {
          if (
            new Date(this.retailWeek?.value?.startingPeriod) < new Date() &&
            new Date(this.retailWeek?.value?.endingPeriod) < new Date()
          ) {
            this.selectedPeriodDate = new Date(
              this.retailWeek?.value?.startingPeriod
            );
            this.setSelectedPeriod()
          } else {
            this.$q.notify({
              type: 'negative',
              position: 'top',
              message: `Please select range lower than present day`
            });
          }
        }
      },
      selectedPeriodDate() {
        if (this.selectMode === 'clicked') {
          this.setSelectedPeriod();
        }
      },
      retailMonth() {
        if (this.retailMonth) {
          if (
            new Date(this.retailMonth?.value?.startingPeriod) < new Date() &&
            new Date(this.retailMonth?.value?.endingPeriod) < new Date()
          ) {
            this.selectMode = 'dropdown';
            this.selectedPeriodDate = new Date(
              this.retailMonth?.value?.startingPeriod
            );
            this.setSelectedPeriod()
          } else {
            this.$q.notify({
              type: 'negative',
              position: 'top',
              message: `Please select range lower than present day`
            });
          }
        }
      },
      retailMonthSelection() {
        this.filterRetailWeeks = this.retailWeeks.filter(v =>
          v.label.includes(this.getSelectedMonth(this.retailMonthSelection.value))
        );
        this.retailWeek = this.filterRetailWeeks[0];
      },
      'filter.daysOfWeek': {
        handler: 'refreshPage'
      },
     'filter.storeCodes':function (newData) {
        this.refreshPage()
      },
      selectedStoreCode: {
        handler: 'refreshPage'
      },
      selectedStore(newData){
        this.updateCloseCalender(this.stores, [newData.value])
        this.selectedCities([{cityId:this.selectedStore.cityId}])
      },
      selectedPeriod: function() {
        this.getSelectedDateRange();
      },
      dateOptionsSelectedModel() {
        this.getSelectedDateRange();
        if (this.dateOptionsComparedModel.value === 'PREVIOUS_PERIOD') {
          const diffDays = differenceInDays(
            this.selectedPeriod.start,
            this.selectedPeriod.end
          );
          const diffDaysPositive =
            diffDays < 0 ? (diffDays - 1) * -1 : diffDays + 1;
          const weeks = Math.ceil((diffDaysPositive / 7).toFixed(2));
          this.comparedPeriod.start = addWeeks(this.selectedPeriod.start, -weeks);
          this.comparedPeriod.end = addWeeks(this.selectedPeriod.end, -weeks);
        }
        else if (
          this.dateOptionsComparedModel.value === 'PREVIOUS_YEAR_SAME_PERIOD'
        ) {
          this.comparedPeriod.start = addYears(
            new Date(this.selectedPeriod.start),
            -1
          );
          this.comparedPeriod.end = addYears(
            new Date(this.selectedPeriod.end),
            -1
          );
        }  //PREVIOUS_YEAR_SAME_DAY_OF_WEEK
        else if (
          this.dateOptionsComparedModel.value === 'PREVIOUS_YEAR_SAME_DAY_OF_WEEK'
        ) {
          this.comparedPeriod.start = addYears(
            new Date(this.selectedPeriod.start),
            -1
          );
          this.comparedPeriod.end = addYears(
            new Date(this.selectedPeriod.end),
            -1
          );
        }
        else if (
          this.dateOptionsComparedModel.value === 'previous_year_same_week'
        ) {
          // this.comparedPeriod= {}
          const prevYear =
            this.retailCalendar.find(
              v => v.label.toString() === (this?.retailYear?.value - 1).toString()
            ) || null;
          const weeksToAdd = this.retailWeeks.findIndex(
            v => v === this.retailWeek
          );
          if (prevYear) {
            const startDateRetail = prevYear.startDate;
            const endOfStartDateRetail = moment(
              addDays(new Date(startDateRetail), 6)
            ).format('YYYY-MM-DD');
            // let startDateRetail = prevYear.startDate;
  
            this.comparedPeriod.start = addWeeks(
              new Date(startDateRetail),
              weeksToAdd
            );
            this.comparedPeriod.end = addWeeks(
              new Date(endOfStartDateRetail),
              weeksToAdd
            );
          } else {
            const currYear = this.retailCalendar.find(
              v => v.label.toString() === this.retailYear.value.toString()
            );
            const startDateRetail = currYear.startDate;
            const endOfStartDateRetail = moment(
              addDays(new Date(startDateRetail), 6)
            ).format('YYYY-MM-DD');
            this.comparedPeriod.start = addWeeks(
              new Date(startDateRetail),
              weeksToAdd - 52
            );
            this.comparedPeriod.end = addWeeks(
              new Date(endOfStartDateRetail),
              weeksToAdd - 52
            );
          }
        } else if (
          this.dateOptionsComparedModel.value === 'previous_year_same_month'
        ) {
          let retailDetails =
            this.retailCalendar.find(
              v => v.label.toString() === (this.retailYear?.value - 1).toString()
            ) || null;
          if (retailDetails) {
            const prevYearMonths = this.getRetailMonths(
              retailDetails.startDate,
              12,
              retailDetails?.numberOfWeeks || 52
            );
  
            const currMonthIndex = this.retailMonths.findIndex(
              v => v === this.retailMonth
            );
  
            this.comparedPeriod.start = new Date(
              prevYearMonths[currMonthIndex]?.value?.startingPeriod
            );
            this.comparedPeriod.end = new Date(
              prevYearMonths[currMonthIndex]?.value?.endingPeriod
            );
          } else {
            this.comparedPeriod.start = addYears(this.selectedPeriod.start, -1);
            this.comparedPeriod.end = addYears(this.selectedPeriod.end, -1);
          }
        }
        this.setMonthPoints();
      },
    },
    async mounted() {
      
    },
    async created() {
      this.updateCloseCalender(this.stores, [this.selectedStore.value])
      this.init()
    }, // created
    methods: {
       ...mapActions("weather", ["getWeather", "getCompareWeather"]),
      ...mapActions("filter", ["fetchHolidays"]),
      ...mapMutations('filter', [
        'setPulseStartingPeriod',
        'setPulseEndingPeriod',
        'setPulseStartingCompared',
        'setPulseEndingCompared',
        'setCustomCalenderFlag'
      ]),
      ...mapMutations('periodicReport', [
        'setLastTimeStamp',
        'setMonthlyPoints',
        'setYearlyPoints',
        'setHoulyChartDate'
      ]),
      ...mapActions('comparison', [
        'fetchCompareDates',
        'fetchMarketingCampaign'
      ]),
      updateCloseCalender(data, selectedStores){
        const dataAccessStores = data?.dataAccessStores;
        if(dataAccessStores){
          const filteredStores = dataAccessStores.filter(store => selectedStores?.includes(store.storeNumber));
          const filteredTimings = filteredStores.flatMap(store => store.storeTimings.filter(timing => timing.dates));
          const formattedData = filteredTimings.flatMap(timing => timing.dates.map(date => (
            { 
              "key": "Closed",
              "popover": {
                  "label": timing.day,
                  "labelClass": "white-text",
                  "labelStyle": {
                      "color": "white"
                  },
                  "class": "white-text"
              },
              "dates": date,
              "bar": {
                style: {
                    backgroundColor: 'blue',
                  },
                  // "color": "blue",
                  "class": "blue-bar"
              }
          })));
          const filter = this.attrs.filter(item => item.key != "Closed");
          const filter2 = this.attrsCompared.filter(item => item.key != "Closed");
          this.attrs = [...filter, ...formattedData];
          this.attrsCompared = [...filter2, ...formattedData];
        }
      },
      async init() {
        await this.fetchHolidays();
        this.startDayFromMonday = getDayDiffFromMonday();
        this.startDayOfWeek = getStartDayOfWeek();
        // if (this.$route.fullPath.includes('weekly-report')) {
        //   this.dateOptionsCompared.push({
        //     label: this.$t('previous_year_same_week'),
        //     value: 'PREVIOUS_YEAR_SAME_WEEK'
        //   });
        // } else if (this.$route.fullPath.includes('monthly-report')) {
        //   this.dateOptionsCompared.push({
        //     label: this.$t('previous_year_same_month'),
        //     value: 'previous_year_same_month'
        //   });
        // }
  
        this.retailYears = this.retailCalendar.map(v => ({
          label: v.label,
          value: v.label
        }));
        this.getRetailWeeks(
          this.startDateOfRetailYear,
          12,
          this.numberofRetailWeeks
        );
        if (this.$route.fullPath.includes('daily-report')) {
          if (!this.$route.params.reportId) {
            this.selectedPeriod.start = new Date();
            this.selectedPeriod.end = new Date();
          } else {
            this.selectedPeriod.start = new Date(this.filter?.startingPeriod);
            this.selectedPeriod.end = new Date(this.filter?.startingPeriod);
          }
        } else {
          let datePickerOptions = localStorage.getItem('ReportCalender_' + this.calendartype);
          datePickerOptions = JSON.parse(datePickerOptions);
          if(datePickerOptions) {
            this.selectedPeriod.start = new Date(datePickerOptions.selectedPeriod.start);
            this.selectedPeriod.end = new Date(datePickerOptions.selectedPeriod.end);
            this.comparedPeriod.start = new Date(datePickerOptions.comparedPeriod.start);
            this.comparedPeriod.end = new Date(datePickerOptions.comparedPeriod.end);
            this.selectedOption = datePickerOptions.selectedOption;
            this.selectedPeriodState = datePickerOptions.selectedPeriodState;
            if(datePickerOptions.dateOptionsComparedModel.value == "CUSTOM") {
              setTimeout(this.onDayClickCompare, 0)
            }
            if(datePickerOptions.dateOptionsSelectedModel.value == "CUSTOM") {
              setTimeout(this.onDayClick, 0)
            }
          }
          this.selectedPeriod.start = new Date(this.filter?.startingPeriod);
          this.selectedPeriod.end =
            this.calendartype === 'weekly'
              ? addWeeks(this.selectedPeriod.start, 1)
              : addMonths(this.selectedPeriod.start, 1);
        }
        
        this.calenderMode = this.selecttype;
        this.setMonthPoints();
        this.getSelectedDateRange();
        this.selectedPeriodDate = this.selectedPeriod.start;
        this.setSelectedPeriod();
        this.setDates();
      },
      setAttrs() {
        this.attrsCompared = [
          ...this.holidays.map((v) => ({
            key: "Holidays",
            // highlight: true,
            popover: {
              label: v.value,
              labelClass: "white-text",
              labelStyle: { color: "white" },
              class: "white-text",
            },
            dates: v.date,
            bar: {
              color: "red",
              class: "my-dot-class",
            },
          })),
        ];
        this.attrs = [
          {
            ...this.attrs[0],
            dates: [
              ...this.getDates(this.selectedPeriod.start, this.selectedPeriod.end)
            ]
          },
          // {
            ...this.holidays.map((v) => ({
              key: "Holidays",
              // highlight: true,
              popover: {
                label: v.value,
                labelClass: "white-text",
                labelStyle: { color: "white" },
                class: "white-text",
              },
              dates: v.date,
              bar: {
                color: "red",
                class: "my-dot-class",
              },
            }))
          // }
        ];
      },
      onDayClickCompare() {
        this.dateOptionsComparedModel = {
          label: this.$t('custom_range'),
          value: 'CUSTOM',
          disable: true
        };
      },
      getFormattedDate(date) {
        let retVal = date ? moment(new Date(date))?.format('YYYY/MM/DD') : "Invalid date";
        if(retVal.trim() == "Invalid date") {
          retVal = ""
        }
        return retVal;
      },
      setSelectedPeriod() {
        let endDate = null;
        if (this.calendartype === 'daily') {
          this.selectedPeriod.start = this.selectedPeriodDate;
          this.selectedPeriod.end = this.selectedPeriodDate;
        } else if (this.calendartype === 'weekly') {
          this.selectedPeriod.start = this.selectedPeriodDate;
          endDate = addDays(addWeeks(this.selectedPeriodDate, 1), -1);
          this.selectedPeriod.end = endDate >= new Date() ? new Date() : endDate;
        } else if (this.calendartype === 'monthly') {
          this.selectedPeriod.start = this.selectedPeriodDate;
          if (this.selectMode === 'clicked') {
            endDate = addDays(addMonths(this.selectedPeriodDate, 1), -1);
          } else {
            endDate = new Date(this.retailMonth.value.endingPeriod);
          }
          // let isRetailMode = differenceInDays(this.selectedPeriodDate, endDate);
          this.selectedPeriod.end = endDate >= new Date() ? new Date() : endDate;
        }
        this.setAttrs()
        this.dateOptionsComparedModelChanged();
        this.setMonthPoints();
      },
      getSelectedMonth(val) {
        if (val.includes('Jan')) return '/01/';
        else if (val.includes('Feb')) return '/02/';
        else if (val.includes('Mar')) return '/03/';
        else if (val.includes('Apr')) return '/04/';
        else if (val.includes('May')) return '/05/';
        else if (val.includes('Jun')) return '/06/';
        else if (val.includes('Jul')) return '/07/';
        else if (val.includes('Aug')) return '/08/';
        else if (val.includes('Sep')) return '/09/';
        else if (val.includes('Oct')) return '/10/';
        else if (val.includes('Nov')) return '/11/';
        else if (val.includes('Dec')) return '/12/';
      },
      getRetailMonths(startingDateVal, noOfMonths, noOfWeeks = 52) {
        let startingMonth = new Date(startingDateVal);
        let monthsVal = [];
        for (let i = 0; i < noOfMonths; i++) {
          let monthStart = moment(startingMonth).format('YYYY-MM-DD');
          let monthEnd = moment(
            addDays(addWeeks(startingMonth, (i + 1) % 3 === 0 ? 5 : 4), -1)
          ).format('YYYY-MM-DD');
          startingMonth = addWeeks(startingMonth, (i + 1) % 3 === 0 ? 5 : 4);
          monthsVal.push({
            label:
              moment(monthStart).format('YYYY/MM/DD') +
              ' - ' +
              moment(monthEnd).format('YYYY/MM/DD'),
            value: {
              startingPeriod: monthStart,
              endingPeriod: monthEnd
            }
          });
        }
        if (noOfWeeks === '53') {
          monthsVal[10].value.endingPeriod = moment(
            addWeeks(new Date(monthsVal[10].value.endingPeriod), 1)
          ).format('YYYY-MM-DD');
          monthsVal[10].label =
            monthsVal[10]?.value?.startingPeriod.replace('-', '/') +
            ' - ' +
            monthsVal[10]?.value?.endingPeriod.replace('-', '/');
          monthsVal[11].value.startingPeriod = moment(
            addWeeks(new Date(monthsVal[11].value.startingPeriod), 1)
          ).format('YYYY-MM-DD');
          monthsVal[11].value.endingPeriod = moment(
            addWeeks(new Date(monthsVal[11].value.endingPeriod), 1)
          ).format('YYYY-MM-DD');
          monthsVal[11].label =
            monthsVal[11].value?.startingPeriod.replace('-', '/') +
            ' - ' +
            monthsVal[11].value?.endingPeriod.replace('-', '/');
        }
        return monthsVal;
      },
      getRetailWeeks(startingDateVal, noOfMonths, noOfWeeks) {
        let startingDate = startingDateVal ? new Date(startingDateVal) : null;
  
        this.retailWeeks = [];
        this.retailMonths = this.getRetailMonths(
          startingDateVal,
          noOfMonths,
          noOfWeeks
        );
        this.retailMonth = startingDate ? this.retailMonths[0] : null;
        for (let i = 0; i < noOfWeeks; i++) {
          let weekStart = moment(startingDate).format('YYYY-MM-DD');
          let weekEnd = moment(addDays(addWeeks(startingDate, 1), -1)).format(
            'YYYY-MM-DD'
          );
          const weekVal = {
            label:
              moment(weekStart).format('YYYY/MM/DD') +
              ' - ' +
              moment(weekEnd).format('YYYY/MM/DD'),
            value: {
              startingPeriod: weekStart,
              endingPeriod: weekEnd
            }
          };
          startingDate = addWeeks(startingDate, 1);
          this.retailWeeks.push(weekVal);
        }
      },
      getSelectedDateRange() {
        if (this.calenderMode !== 'single') {
          let dates = [];
          let startDate = this.selectedPeriod.start;
          while (startDate <= this.selectedPeriod.end) {
            dates.push(startDate);
            startDate = addDays(startDate, 1);
          }
          this.selectedDateRange = dates.map(dat => this.formatDate(dat));
        }
      },
      dateOptionsSelectedModelChanged() {
        const selectedOption = this.dateOptionsSelectedModel.value;
        this.marketingCampaignMode = false;
        this.dateOptionsComparedModelChanged();
      },
      dateOptionsComparedModelChanged() {
        // const selectedOption = this.dateOptionsSelectedModel.value;
        const comparedOption = this.dateOptionsComparedModel.value;
        this.calenderMode = 'range';
        if (comparedOption === 'PREVIOUS_PERIOD') {
          this.comparedPeriod = {};
  
          const diffDays = differenceInDays(
            this.selectedPeriod.start,
            this.selectedPeriod.end
          );
          const diffDaysPositive =
            diffDays < 0 ? (diffDays - 1) * -1 : diffDays + 1;
          const weeks = Math.ceil((+diffDaysPositive / 7).toFixed(2));
          this.comparedPeriod.start = addWeeks(this.selectedPeriod.start, -weeks);
          this.comparedPeriod.end = addWeeks(this.selectedPeriod.end, -weeks);
        } else if (comparedOption === 'PREVIOUS_YEAR_SAME_PERIOD') {
          this.comparedPeriod = {};
          // this.comparedPeriod.start = addYears(new Date(), -1);
          // this.comparedPeriod.end = addYears(new Date(), -1);
          // const diffDays = differenceInDays(
          //   this.selectedPeriod.start,
          //   this.selectedPeriod.end
          // );
          
          this.comparedPeriod.start = addYears(this.selectedPeriod.start, -1);
          this.comparedPeriod.end = addYears(this.selectedPeriod.end, -1);
           
          // this.comparedPeriod.start = addDays(new Date(this.selectedPeriod.start), -365)
          // this.comparedPeriod.end = addDays(new Date(this.selectedPeriod.end), -365)
          this.comparedPeriod.start = subYears(new Date(this.selectedPeriod.start), 1);
          this.comparedPeriod.end = subYears(new Date(this.selectedPeriod.end), 1);
        }
        //PREVIOUS_YEAR_SAME_DAY_OF_WEEK
        else if (comparedOption === 'PREVIOUS_YEAR_SAME_DAY_OF_WEEK') {
          this.comparedPeriod = {};
          // this.comparedPeriod.start = addYears(new Date(), -1);
          // this.comparedPeriod.end = addYears(new Date(), -1);
          // const diffDays = differenceInDays(
          //   this.selectedPeriod.start,
          //   this.selectedPeriod.end
          // );
          // this.comparedPeriod.start = addYears(this.selectedPeriod.start, -1);
          // this.comparedPeriod.end = addYears(this.selectedPeriod.end, -1);
          this.comparedPeriod.start = addDays(new Date(this.selectedPeriod.start), -364)
          this.comparedPeriod.end = addDays(new Date(this.selectedPeriod.end), -364)
        }
        else if (comparedOption === 'previous_year_same_week') {
          this.comparedPeriod= {}
          const prevYear =
            this.retailCalendar.find(
              v =>
                v.label.toString() ===
                (
                  this.retailYear?.value ??
                  new Date(this.selectedPeriod.start).getFullYear() - 1
                ).toString()
            ) || null;
          const weeksToAdd = this.retailWeeks.findIndex(
            v => v === this.retailWeek
          );
          if (prevYear) {
            const startDateRetail = prevYear.startDate;
            const endOfStartDateRetail = moment(
              addDays(new Date(startDateRetail), 6)
            ).format('YYYY-MM-DD');
            // let startDateRetail = prevYear.startDate;
  
            this.comparedPeriod.start = addWeeks(
              new Date(startDateRetail),
              weeksToAdd
            );
            this.comparedPeriod.end = addWeeks(
              new Date(endOfStartDateRetail),
              weeksToAdd
            );
          } else {
            const currYear = this.retailCalendar.find(
              v => v.label.toString() === this.retailYear.value.toString()
            );
            const startDateRetail = currYear.startDate;
            const endOfStartDateRetail = moment(
              addDays(new Date(startDateRetail), 6)
            ).format('YYYY-MM-DD');
            this.comparedPeriod.start = addWeeks(
              new Date(startDateRetail),
              weeksToAdd - 52
            );
            this.comparedPeriod.end = addWeeks(
              new Date(endOfStartDateRetail),
              weeksToAdd - 52
            );
          }
        } else if (comparedOption === 'previous_year_same_month') {
          this.comparedPeriod = {};
          let retailDetails =
            this.retailCalendar.find(
              v => v.label.toString() === (this.retailYear?.value - 1).toString()
            ) || null;
          if (retailDetails) {
            const prevYearMonths = this.getRetailMonths(
              retailDetails.startDate,
              12,
              retailDetails.numberOfWeeks
            );
  
            const currMonthIndex = this.retailMonths.findIndex(
              v => v === this.retailMonth
            );
  
            this.comparedPeriod.start = new Date(
              prevYearMonths[currMonthIndex]?.value?.startingPeriod
            );
            this.comparedPeriod.end = new Date(
              prevYearMonths[currMonthIndex]?.value?.endingPeriod
            );
          } else {
            this.comparedPeriod.start = addYears(this.selectedPeriod.start, -1);
            this.comparedPeriod.end = addYears(this.selectedPeriod.end, -1);
            return;
          }
        }
        this.setMonthPoints();
      },
      getComparedDatesArray() {
        this.comparedPeriod = {};
        this.comparedPeriod = this.selectedDateRange.map(
          dat => new Date(this.getComparedDate(dat))
        );
      },
      setDates() {
        this.setPulseStartingPeriod(moment(this.selectedPeriod.start).format('YYYY-MM-DD'));
        this.setPulseEndingPeriod( moment(this.selectedPeriod.end).format('YYYY-MM-DD'));
        this.setPulseStartingCompared(moment(this.comparedPeriod.start).format('YYYY-MM-DD'));
        this.setPulseEndingCompared(moment(this.comparedPeriod.end).format('YYYY-MM-DD'));
      },
      selectedCities(citiesList){
        this.$store.commit('weather/setCitiesListWeather', citiesList )
        this.fetchWeather(citiesList)
      //  this.fetchWeather([{cityId:'中央区'}])
      },
     fetchWeather(cityName) {
        // if(this.$route.path == '/daily-report'){}
        // localStorage.removeItem("weather");
        // localStorage.removeItem("weatherCompare");
        // filter base
        var startDate = null;
        var endDate = null;
        var startDateComp = null;
        var endDateComp = null;
        if (this.calendartype === 'daily') {
          startDate = moment(this.getPulseStartingPeriod);
          endDate = moment(this.getPulseEndingPeriod);
          startDateComp = moment(this.getPulseStartingCompared);
          endDateComp = moment(this.getPulseEndingCompared);
        } else {
          // form base
          // startDate = moment(this.selectedPeriod.start);
          // endDate = moment(this.selectedPeriod.end);
          // startDateComp = moment(this.comparedPeriod.start);
          // endDateComp = moment(this.comparedPeriod.end);
          startDate = moment(this.getPulseStartingPeriod);
          endDate = moment(this.getPulseEndingPeriod);
          startDateComp = moment(this.getPulseStartingCompared);
          endDateComp = moment(this.getPulseEndingCompared);
        }
        // if(this.calendartype === 'weekly'){
        //   startDate = endDate
        //   startDateComp = endDateComp
        // }
        // for hourly chart
        this.setHoulyChartDate({
          selectedPeriod: this.selectedPeriod,
          comparedPeriod: this.comparedPeriod
        });
        if (
          this.$route.path == '/daily-report' ||
          this.$route.path == '/monthly-report'
        ) {
          setTimeout(() => {
            if (cityName && !cityName.length) return;
            var check = startDate == startDateComp && endDate == endDateComp;
            this.getWeather({ startDate, endDate, cityName })
              .then(res => {
                // console.log(res, 'weather')
              })
              .catch(err => {
                console.log(err, 'error');
              });
            if (this.$route.path == '/daily-report') {
              if (!check) {
                this.getCompareWeather({
                  startDate: startDateComp,
                  endDate: endDateComp,
                  cityName
                })
                  .then(res => {
                    // console.log(res, 'weatherC')
                  })
                  .catch(err => {
                    console.log(err, 'error');
                  });
              }
            }
          }, 1000);
        }
      },
      refreshPage() {
        this.$emit('refreshPage');
      },
      submitDate() {
        // this.setdatefilter(dateFilter);
        if(!this?.selectedPeriod?.start) return;
        this.setDates();
        this.setLastTimeStamp();
        this.selectedCities([{cityId:this.selectedStore.cityId}])
        this.hide();
        this.hide2();
        this.refreshPage();
      }, // submitDate
      hide() {
        this.$refs.periodFilter.hide();
      },
      hide2() {
        this.$refs.periodFilter2.hide();
      },
      formatDate(date) {
        if (!date) return null;
        let newDate = moment(date).format('YYYY/MM/DD');
        const [year, month, day] = newDate.split('-');
        // return `${year}-${month}-${day}`;
        return newDate;
      },
      getDates(startDate, stopDate) {
        let dateArray = [];
        let currentDate = moment(startDate);
        stopDate = moment(stopDate);
  
        while (currentDate <= stopDate) {
          dateArray.push(moment(currentDate).format('YYYY-MM-DD'));
          currentDate = moment(currentDate).add(1, 'days');
        }
        return dateArray;
      },
      getComparedDate(date) {
        if (date && this.compareDates) {
          let dateObj = this.compareDates.filter(dat => dat.date === date);
          if (dateObj.length > 0) {
            return new Date(dateObj[0].comparedDate);
          } else {
            return '';
          }
        }
      },
      setMonthPoints() {
        if (this.$route.fullPath.includes('weekly-report')) {
          {
            let selectYear = this.retailYear
              ? this.retailYear.value
              : moment(new Date(this.selectedPeriod.start)).format('YYYY');
            const retailCal = this.retailCalendar.find(
              v => v.label.toString() === selectYear.toString()
            );
  
            const compRetailCal = this.retailCalendar.find(
              v => v.label.toString() === (parseInt(selectYear) - 1).toString()
            );
            let { numberOfWeeks, startDate } = retailCal ? retailCal : {};
            const currentMonthList = this.getRetailMonths(
              startDate,
              12,
              numberOfWeeks
            );
            let { compNumberOfWeeks, compStartDate } = compRetailCal
              ? {
                  compNumberOfWeeks: compRetailCal.numberOfWeeks || 52,
                  compStartDate: compRetailCal.startDate
                }
              : {};
            const nonRetailMonth = {
              startingPeriod: moment(
                startOfMonth(new Date(this.filter?.startingPeriod))
              ).format('YYYY-MM-DD'),
              endingPeriod: moment(
                endOfMonth(new Date(this.filter?.startingPeriod))
              ).format('YYYY-MM-DD')
            };
  
            let monthIndex = currentMonthList.findIndex(v => {
              return (
                this.selectedPeriodDate >= new Date(v.value?.startingPeriod) &&
                addDays(this.selectedPeriodDate, 6) <=
                  new Date(v.value.endingPeriod)
              );
            });
            monthIndex =
              monthIndex === -1
                ? currentMonthList.findIndex(v => {
                    return (
                      this.selectedPeriod.start >=
                        new Date(v.value?.startingPeriod) &&
                      addDays(this.selectedPeriod.start, 3) <=
                        new Date(v.value.endingPeriod)
                    );
                  })
                : monthIndex;
            let month = this.retailMonths[monthIndex]?.value ?? nonRetailMonth;
            let monthCompared = {};
  
            const prevYearMonthList = this.getRetailMonths(
              compStartDate,
              12,
              compNumberOfWeeks
            );
  
            if (
              [
                'PREVIOUS_YEAR_SAME_PERIOD',
                'PREVIOUS_YEAR_SAME_DAY_OF_WEEK',
                'PREVIOUS_PERIOD',
                'PREVIOUS_YEAR_SAME_WEEK'
              ].includes(this.dateOptionsComparedModel.value)
            ) {
              monthCompared = prevYearMonthList[monthIndex]?.value;
              // monthCompared = {
              //   startingPeriod: moment(
              //     addDays(addYears(new Date(month.startingPeriod), -1), -1)
              //   ).format('YYYY-MM-DD'),
              //   endingPeriod: moment(
              //     addYears(new Date(month.endingPeriod), -1)
              //   ).format('YYYY-MM-DD')
              // };
            } else {
              monthCompared = {
                startingPeriod: moment(
                  addYears(new Date(month?.startingPeriod), -1)
                ).format('YYYY-MM-DD'),
                endingPeriod: moment(
                  addYears(new Date(month?.endingPeriod), -1)
                ).format('YYYY-MM-DD')
              };
            }
            let year = {};
            if (numberOfWeeks && startDate) {
              year = {
                startingPeriod: startDate,
                endingPeriod: moment(
                  addDays(addWeeks(new Date(startDate), numberOfWeeks), -1)
                ).format('YYYY-MM-DD')
              };
            } else {
              year = {
                startingPeriod: moment(
                  startOfYear(new Date(this.filter?.startingPeriod))
                ).format('YYYY-MM-DD'),
                endingPeriod: moment(
                  addDays(endOfYear(new Date(this.filter.startingPeriod)), -1)
                ).format('YYYY-MM-DD')
              };
            }
  
            let yearCompared = {};
  
            if (compNumberOfWeeks && compStartDate) {
              yearCompared = {
                startingPeriod: compStartDate,
                endingPeriod: moment(
                  addDays(
                    addWeeks(new Date(compStartDate), compNumberOfWeeks),
                    -1
                  )
                ).format('YYYY-MM-DD')
              };
            } else {
              yearCompared = {
                startingPeriod: moment(
                  addYears(startOfYear(new Date(this.filter.startingPeriod)), -1)
                ).format('YYYY-MM-DD'),
                endingPeriod: moment(
                  addYears(endOfYear(new Date(this.filter?.startingPeriod)), -1)
                ).format('YYYY-MM-DD')
              };
            }
            this.setMonthlyPoints({ current: month, compared: monthCompared });
            this.setYearlyPoints({ current: year, compared: yearCompared });
          }
        }
      },
      getPreviousPeriod(val) {
        let compPeriod = { startingPeriod: null, endingPeriod: null };
        const diffDays = differenceInDays(val?.startingPeriod, val.endingPeriod);
        const diffDaysPositive =
          diffDays < 0 ? (diffDays - 1) * -1 : diffDays + 1;
        const weeks = Math.ceil((+diffDaysPositive / 7).toFixed(2));
        compPeriod.startingPeriod = moment(
          addWeeks(val?.startingPeriod, -weeks)
        ).format('YYYY-MM-DD');
        compPeriod.endingPeriod = moment(
          addWeeks(val.endingPeriod, -weeks)
        ).format('YYYY-MM-DD');
        return compPeriod;
      },
      clickDateSelector(e) {
        this.selectMode = 'clicked';
        this.setSelectedPeriod();
      },
      dropDownToggled(val) {
        if(val == 1) {
          this.hide2();
        } else if(val == 2) {
          this.hide()
        }
      },
      getDayDiffFromMonday() {
        const weekday = ["friday","saturday", "sunday", "monday", "tuesday", "wednesday", "thursday"];
        let i = weekday.indexOf(this?.defaultSettings?.weekStartDay?.toLowerCase())
        this.startDayFromMonday = i - 3;
        const indexes_of_weekday = [0,1,2,3,4,5,6];
      },
      getStartDayOfWeek() {
        const weekday = ["sunday","monday", "tuesday", "wednesday", "thursday","friday","saturday"];
        this.startDayOfWeek = weekday.indexOf(this?.defaultSettings?.weekStartDay?.toLowerCase())
      },
    } // methods
  }; // export default
  