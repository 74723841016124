

import {  mapGetters } from 'vuex';
// import { defineProps, defineEmits, toRefs } from "vue";

// const emit = defineEmits("getChat", "mouseOverAvatar", "mouseLeaveAvatar");
export default {
  props: {
    selectedPeriod: {
      type: [Date, Object],
      default: () => new Date()
    },
    format: {
      type: String,
      default:""
    },
    startOfWeek : {
      type: Number,
      default:0
    },
    classes: {
      type: String,
      default:""
    },
    mode: {
      type: String,
      default:"range"
    },
    options: {
      type: Array,
      default: () => []
    },
    tutuannaMode: {
      type: Boolean,
      default: false
    },
    dragComparedValue: {
      type: String,
      default:""
    },
    attributes: {
      type: Array,
      default: () => []
    }
  },

 
  data () {
    return {
      lastDateScroll: 0,
      datePickerRows: 6,
      scrollJump: 6,
      defaultRows: 18,
      disabledReset: false,
      s: 0,
      
      masks: {
        weekdays: 'WW'
      },
    }
  },
  computed: {
    ...mapGetters('user', ['getLocale']),
  },
  watch: {
    selectedPeriod: {
      deep: true,
      //handle the change
      handler() {
        setTimeout(this.triggerHighlight, 200)
      }
    }
  },
  mounted() {
    const startObserving = (domNode, classToLookFor) => {
      const observer = new MutationObserver(mutations => {
        mutations.forEach(function (mutation) {

          const elementAdded = Array.from(mutation.addedNodes).some(
            element => {
              if (element.classList) {
                return element.classList.contains(classToLookFor);
              } else {
                return false;
              }
            },
          );
          if (elementAdded) {
            this?.triggerHighlight();
          }
        });
      });

      observer.observe(domNode, {
        childList: true,
        attributes: true,
        characterData: true,
        subtree: true,
      });

      return observer;
    };

    startObserving(document.body, 'q-position-engine');
    this.resetDatePicker('datePicker')
  },
  methods: {
  triggerHighlight() {
    clearTimeout(this.s);
    this.s = setTimeout(this.focusHighlighted, 400)
  },
  focusHighlighted() {
    const allH = document.querySelectorAll(".calender-card .vc-weeks .vc-highlight.vc-bg-blue-600");
    let found = -1;
    for(let i=0; i< allH.length; i++) {
      if(allH[i].offsetParent && !allH[i].closest(".vc-opacity-0")) {
        found=i;
        const thisEle = allH[i];
        thisEle?.closest(".vc-pane").scrollIntoView(true);
        break;
      }
    }
    if(found == -1) {
      this.triggerHighlight();
    }
  },
    handleClick(v) {
      this.$emit('click', v)
    },
    handleInput(v) {
      this.disabledReset = true;
      setTimeout(() => {
        this.disabledReset = false;
      }, 500)
      this.$emit('input', v)
    },
    handleDrag(v) {
      this.$emit('dragChange', v.date)
    },
    handleDayClick(v) {
      this.$emit('dayclick', v)
    },
    handleDayMouseEnter(v) {
      setTimeout(() => {
        let pop = document.querySelectorAll('.calender-card .vc-w-full+.vc-popover-content-wrapper')[0]
        if(pop?.style?.transform) {
          let t = pop?.style?.transform;
          let cS = t.split(',')
          let p1 = cS[0].split('(')
          let v = Number(p1.pop().split('px')[0])
          let b = pop.getBoundingClientRect();
          let p = pop.parentElement.getBoundingClientRect();
          let l = b.left
          if(v<0) {
            let d = Math.abs(v);
            cS[0] = 'translate3d(0px'
            pop.style.transform = cS.join(',')
            pop.children[0].children[1].style.left = `calc(50% + ${-d}px)`
          } else {
            let d = (v + b.width - 290)
            if (d > 0) {
              cS[0] = 'translate3d('+(v-d)+'px'
              pop.style.transform = cS.join(',')
              pop.children[0].children[1].style.left = `calc(50% + ${d}px)`
            }
          }
        }
      }, 500)
      this.$emit('daymouseenter', v)
    },
    selectDragAttribute() {
      return {
        popover: {
          visibility: this.tutuannaMode ? 'hover' : 'none',
          isInteractive: false // * Defaults to true when using slot
        }
      };
    },
    getDatePickerRows(d) {
      if(d?.start && typeof new Date(d?.start)?.getMonth === 'function') {
        d = new Date(d?.start)
      } else if(d && new Date(d)?.getMonth) {
        d = new Date(d)
      }
      if(d) {
        let today = new Date()
        let y = today.getYear() - d.getYear()
        let m = today.getMonth() - d.getMonth()
        if(this.datePickerRows < (y*12 + m)) {
          this.datePickerRows = y*12 + m + 3
        }
      }
      return this.datePickerRows;
    },
    resetDatePicker(val) {
      if(this.selectedDateId) {
          this.$refs[this.selectedDateId]?.$el?.parentElement.removeEventListener('scroll',this.scollHandleDate);
          this.lastDateScroll= 0
          this.datePickerRows = this.defaultRows
      }
      this.selectedDateId = val
      if(val) {
        setTimeout(this.bindDatePickerScroll, 500, val)
      }
    },
    scollHandleDate(e) {
      if(!this.isScrolling && this.lastDateScroll >= e.target.scrollTop && e.target.scrollTop < 1) {
        this.isScrolling = true;
        this.datePickerRows += this.scrollJump;
        setTimeout(() => {
          e.target.scrollTop = this.lastDateScroll + 235 * (this.scrollJump - 1)
          this.lastDateScroll += 235 * (this.scrollJump - 1)
        }, 500)
        setTimeout(() => {
          this.isScrolling = false;
        }, 500)
      }
      this.lastDateScroll = e.target.scrollTop
    },
    bindDatePickerScroll(val) {
      if(val) {
        this.$refs[val]?.$el?.parentElement.addEventListener('scroll',this.scollHandleDate);
      }
    },
  }
};
